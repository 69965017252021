import { Injectable, OnDestroy } from '@angular/core';
import { GeoCoordinates } from '@app/models';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { ReplaySubject, Subscription, BehaviorSubject } from 'rxjs';
import { equals } from 'ramda';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LocationService implements OnDestroy {
  private locationSubject$ = new BehaviorSubject<GeoCoordinates>({ lat: 0, lng: 0 });
  private subscription: Subscription;

  public location$ = this.locationSubject$.asObservable();

  constructor(private geolocation: Geolocation, private platform: Platform) {
    this.platform.ready().then(() => {
      this.subscription = this.geolocation
        .watchPosition({
          enableHighAccuracy: true,
          maximumAge: 1000,
        })
        .pipe(
          filter(
            (location): location is Geoposition => (location as Geoposition).coords !== undefined
          ),
          map(({ coords }) => ({ lat: coords.latitude, lng: coords.longitude })),
          distinctUntilChanged((a, b) => equals(a, b))
        )
        .subscribe((position) => this.locationSubject$.next(position));
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
