export enum ButtonPress {
  VOLUMEUP = 'VOLUMEUP',
  VOLUMEDOWN = 'VOLUMEDOWN',
}

type ButtonPresses = keyof typeof ButtonPress;

export interface EnvironmentConfig {
  production: boolean;
  native: boolean;
  password?: string;
  toastDuration: number;
  apiHost: string;
  fileBase?: string;
  routesBase: string;
  triggerDistance: number;
  codes?: {
    /**
     * @description time to enter code in ms
     */
    timelimit: number;
    /**
     * @description code to clear store and return to passwordpage
     * @minItems 1
     */
    resetapp?: ButtonPresses[];
    /**
     * @description code to exit kiosk mode
     * @minItems 1
     */
    exitkiosk?: ButtonPresses[];
    /**
     * @description code to reload page
     * @minItems 1
     */
    closemodal?: ButtonPresses[];
  };
  emulate?: Partial<{
    media: boolean;
    approach: boolean;
    device: string;
    api: boolean;
    files: boolean;
    data: boolean;
    action: boolean;
  }>;
  feature: {
    preview?: boolean;
    reset: boolean;
    offline: boolean;
    chat: boolean;
    route?: string;
  };
  socket: {
    url: string;
    path?: string;
    port: number;
  };
  map: {
    provider: string;
    fallback?: string;
    maxZoom: number;
    minZoom: number;
    useTms: boolean;
    fileProvider?: string;
    subdomains?: string;
  };
}
