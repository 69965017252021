import { Component, OnInit } from '@angular/core';
import { environment } from '@app/env';
import { Rank } from '@app/models';
import { fromStore, RankingApiActions, RootState } from '@app/root-store';
import { AlertController, ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-score-popover',
  templateUrl: './score-popover.component.html',
  styleUrls: ['./score-popover.component.scss'],
})
export class ScorePopoverComponent implements OnInit {
  public isNative = environment.native;
  public journeyHasEnded$ = this.store.pipe(select(fromStore.selectHasEnded));
  public data$ = this.store.pipe(
    select(fromStore.selectRanking),
    map((data) => {
      if (data && data.length) {
        const [score, ...ranking] = data;
        return { score, ranking };
      } else {
        return { ranking: [], score: undefined };
      }
    })
  );

  constructor(
    private modalCtrl: ModalController,
    private store: Store<RootState>,
    private alertCtrl: AlertController
  ) {}

  ngOnInit() {
    this.updateRanking();
  }

  updateRanking() {
    this.store.dispatch(RankingApiActions.updateRanking());
  }

  calculateProgress(points: string) {
    // const [owned, total] = points.split('/');
    // return +owned / +total;
    return !!points ? +points : points;
  }

  trackByMethod(index: number, rank: Rank) {
    return `${rank.team}@${rank.rank}`;
  }

  close(done: boolean) {
    if (done && environment.password) {
      this.alertCtrl
        .create({
          header: 'Zur Routenverwaltung',
          message: 'Bitte geben sie das Passwort ein',
          keyboardClose: false,
          backdropDismiss: false,
          inputs: [
            {
              name: 'password',
              type: 'password',
              placeholder: 'Passwort',
            },
          ],
          buttons: [
            {
              text: 'Abbrechen',
              role: 'cancel',
            },
            {
              text: 'Ok',
            },
          ],
        })
        .then((prompt) => {
          prompt.present();
          return prompt.onDidDismiss();
        })
        .then(({ role, data }) => {
          if (role !== 'cancel') {
            if (data.values.password === environment.password) {
              this.modalCtrl.dismiss({ done });
            } else {
              this.alertCtrl
                .create({
                  header: 'Fehler',
                  message: 'Das eingebene Passwort ist falsch',
                })
                .then((alert) => alert.present());
            }
          }
        });
    } else {
      this.modalCtrl.dismiss({ done });
    }
  }
}
