import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@app/components';
import { environment } from '@app/env';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CacheService } from '../services/cache.service';
import { StoreEffects } from './effects';
import { metaReducers, ROOT_REDUCERS, State } from './reducers';
import { log } from '@app/helpers';

export function cacheMetaReducerFactory(cache: CacheService) {
  return (reducer: ActionReducer<State>): ActionReducer<State> => {
    return (state, action) => {
      log('caching action ' + action.type);
      cache.setAction(action);
      cache.setPrevState(state);
      const result = reducer(state, action);
      cache.setNextState(result);
      return result;
    };
  };
}

export function getMetaReducers(cache: CacheService): MetaReducer<State>[] {
  return [...metaReducers, cacheMetaReducerFactory(cache)];
}

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      // metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictStateSerializability: true,
        strictActionImmutability: true,
        strictActionSerializability: true,
      },
    }),
    !environment.feature.preview ? [EffectsModule.forRoot(StoreEffects)] : [],
    !environment.production ? [StoreDevtoolsModule.instrument()] : [],
  ],
  providers: [
    {
      provide: USER_PROVIDED_META_REDUCERS,
      deps: [CacheService],
      useFactory: getMetaReducers,
    },
  ],
})
export class RootStoreModule {}
