import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CacheService {
  private action: any;
  private prevState: any;
  private nextState: any;

  constructor() {}

  setAction(action: any) {
    this.action = { ...action, _resolved: false };
  }

  setPrevState(prev: any) {
    this.prevState = prev;
  }

  setNextState(next: any) {
    this.nextState = next;
    this.action._resolved = true;
  }

  getCache() {
    return {
      action: this.action,
      prevState: this.prevState,
      nextState: this.nextState,
    };
  }
}
