import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/env';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { is, mapObjIndexed, unless } from 'ramda';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private network: Network) {}

  private isDisconnected(
    msg: string = 'Diese Funktion benötigt eine Internetverbindung'
  ): Observable<never> | false {
    if (!environment.native) {
      return false;
    }
    return this.network.type === this.network.Connection.NONE && throwError(new Error(msg));
  }

  create<T = any>(api: string, data: Params, queryParams?: QueryParams) {
    return (
      this.isDisconnected() ||
      ((environment.emulate && environment.emulate.api
        ? this.http.get(this.toUrl(api))
        : this.http.post(this.toUrl(api), data, this.toParams(queryParams))) as Observable<T>)
    );
  }

  read<T = any>(api: string, args: Id | QueryParams = '', queryParams?: QueryParams) {
    return (
      this.isDisconnected() ||
      ((typeof args === 'object'
        ? this.http.get(this.toUrl(api), this.toParams(args))
        : this.http.get(this.toUrl(api, args), this.toParams(queryParams))) as Observable<T>)
    );
  }

  readAsset<T = any>(api: string, args: Id | QueryParams = '', queryParams?: QueryParams) {
    return (
      typeof args === 'object'
        ? this.http.get(this.toUrl(api), this.toParams(args))
        : this.http.get(this.toUrl(api, args), this.toParams(queryParams))
    ) as Observable<T>;
  }

  update<T = any>(api: string, id: Id, data: Params, queryParams?: QueryParams) {
    return (
      this.isDisconnected() ||
      ((environment.emulate && environment.emulate.api
        ? this.http.get(this.toUrl(api))
        : this.http.put(this.toUrl(api, id), data, this.toParams(queryParams))) as Observable<T>)
    );
  }

  delete<T = any>(api: string, id: Id, queryParams?: QueryParams) {
    return (
      this.isDisconnected() ||
      ((environment.emulate && environment.emulate.api
        ? this.http.get(this.toUrl(api))
        : this.http.delete(this.toUrl(api, id), this.toParams(queryParams))) as Observable<T>)
    );
  }

  upload(api: string, file: Blob) {
    return (
      this.isDisconnected() ||
      (environment.emulate && environment.emulate.api
        ? this.http.get(this.toUrl(api))
        : this.http.put(this.toUrl(api), file))
    );
  }

  private toParams(queryParams: QueryParams = null) {
    return queryParams
      ? {
          params: new HttpParams({
            fromObject: mapObjIndexed(unless(is(String), JSON.stringify), queryParams),
          }),
        }
      : {};
  }

  private toUrl(api: string, id: Id = '') {
    // prepend api base url if endpoint is not:
    // - absolute request url => begins with http[s]://
    // - absolute local url => begins with /
    // - relative local url => contains .[.]/
    return /^(http(s)?:\/\/|\/)|\.{1,2}\//.test(api)
      ? api
      : environment.emulate && environment.emulate.api
      ? `./assets/api/${api}.json`
      : `${environment.apiHost}${api}${id ? `/${id}` : ''}`;
  }
}
