import { latLng } from 'leaflet';
import { environment } from '@app/env';

export interface GeoCoordinates {
  lat: number;
  lng: number;
}

export interface Distance {
  distance: number;
  unit: string;
  isNear: boolean;
}

export const distanceOf = (start: GeoCoordinates, end: GeoCoordinates): Distance => {
  if (
    !start ||
    !end ||
    typeof start.lat !== 'number' ||
    typeof start.lng !== 'number' ||
    typeof end.lat !== 'number' ||
    typeof end.lng !== 'number'
  ) {
    return { distance: null, unit: null, isNear: false };
  }

  const d = latLng(start).distanceTo(end);

  return {
    distance: d <= 1000 ? d : d / 1000,
    unit: d <= 1000 ? 'm' : 'km',
    isNear: d <= environment.triggerDistance,
  };
};
