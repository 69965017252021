import { ChallengeLog, PositionLog } from '@app/models';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { RouteServiceActions, LoggingActions, JourneyActions, ManagePageActions } from '../actions';
import { without } from 'ramda';

export const featureKey = 'logging';

export interface State {
  logSend: number;
  logWrite: number;
  challenges: ChallengeLog[];
  positions: PositionLog[];
}

export const initialState: State = {
  logSend: null,
  logWrite: null,
  challenges: [],
  positions: [],
};

export const reducer = createReducer(
  initialState,
  // on(
  //   RouteServiceActions.startSuccess,
  //   (state, { response: { logSend = null, logWrite = null } }): State => ({
  //     ...state,
  //     logSend,
  //     logWrite,
  //   })
  // ),
  on(
    ManagePageActions.loggingSelected,
    (state, { logSend, logWrite }): State => ({ ...state, logSend, logWrite })
  ),
  on(
    LoggingActions.logPosition,
    (state, { lat, lng, timestamp }): State => ({
      ...state,
      positions: [...state.positions, { lat, lng, timestamp }],
    })
  ),
  on(
    LoggingActions.logChallenge,
    (state, challenge): State => ({ ...state, challenges: [...state.challenges, challenge] })
  ),
  on(
    LoggingActions.sendLogSuccess,
    (state, { challenges, positions }): State => ({
      ...state,
      challenges: without(challenges, state.challenges),
      positions: without(positions, state.positions),
    })
  ),
  on(JourneyActions.clearJourney, () => initialState)
);

export const selectFeatureState = createFeatureSelector<State>(featureKey);
export const selectLogSend = createSelector(selectFeatureState, (state) => state.logSend);
export const selectLogWrite = createSelector(selectFeatureState, (state) => state.logWrite);
export const selectLog = createSelector(selectFeatureState, ({ challenges, positions }) => ({
  challenges,
  positions,
}));
