import { GeoCoordinates } from './location.model';
import { Page } from './page.model';

export interface StationPreview {
  /**
   * @default "./assets/defaults/preview.png"
   */
  image: string;
  title: string;
  description: string;
}

export interface MarkerIcons {
  /**
   * @default "./assets/defaults/icon.png"
   */
  icon: string;
  /**
   * @default "./assets/defaults/target.png"
   */
  target: string;
  /**
   * @default "./assets/defaults/success.png"
   */
  success: string;
  /**
   * @default "./assets/defaults/failure.png"
   */
  failure: string;
  /**
   * @default "./assets/defaults/near.png"
   */
  near: string;
}

export interface Station {
  id: string;
  title: string;
  /**
   * @default {}
   */
  marker: MarkerIcons;
  preview: StationPreview;
  geoposition: GeoCoordinates;
  /**
   * @minItems 1
   */
  pages: Page[];
}

export enum StationStatus {
  OPEN = 'OPEN',
  VISITED = 'VISITED',
}

export type StationEntity = Omit<Station, 'pages'> & {
  status: StationStatus;
};

export interface StationMarker {
  id: string;
  title: string;
  geoposition: GeoCoordinates;
  icon: string;
  visible: boolean;
  visited?: boolean;
}
