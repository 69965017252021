import { createTimestampedAction, createErrorAction, props } from '@app/helpers';

enum Action {
  ENTERED = '[Home Page] Entered With Voucher Code',
  VALIDATION_ERROR = '[Home Page] Voucher Validation Error',
  RESTORE_RESOURCE = '[Home Page] Restore Resource',
  SELECT_ROUTE = '[Home Page] Select Route',
}

export const entered = createTimestampedAction(
  Action.ENTERED,
  props<{ code: string; customer: string }>()
);

export const validationError = createErrorAction(Action.VALIDATION_ERROR);

export const restoreResource = createTimestampedAction(Action.RESTORE_RESOURCE);

export const selectRoute = createTimestampedAction(
  Action.SELECT_ROUTE,
  props<{ customer: string }>()
);
