import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/env';
import { FileUploadOptions } from '@ionic-native/file-transfer/ngx';
import { Entry, FileEntry } from '@ionic-native/file/ngx';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FileService } from './file.service';

@Injectable()
export class WebFileService implements Interface<FileService> {
  private fs: string;

  constructor(private http: HttpClient) {
    this.fs =
      (environment.emulate && environment.emulate.files) || !environment.fileBase
        ? './assets/'
        : environment.fileBase;
  }

  loadJSON<T = any>(dirName: string, fileName: string): Observable<T> {
    return this.http.get(this.fs + dirName + '/' + fileName) as Observable<T>;
  }

  ls(dirName: string) {
    return this.http
      .get(this.getRoot(dirName) + '/ls.json')
      .pipe(catchError(() => of([]))) as Observable<Entry[]>;
  }

  getRoot(dirName: string = '') {
    return this.fs + dirName;
  }

  ensureDir(dirName: string) {
    return of(true);
  }

  toFileUrl(filePath: string) {
    return filePath;
  }

  mkDir(dirName: string, replace = false) {
    return of(dirName);
  }

  rmDir(dirName: string) {
    return of(dirName);
  }

  mkFile(fileName: string, replace = false) {
    return of(fileName);
  }

  rmFile(fileName: string) {
    return of(fileName);
  }

  writeFile(fileName: string, data: string, replace = false) {
    return (of(null) as unknown) as Observable<any>;
  }

  readFile(fileName: string) {
    return (of(null) as unknown) as Observable<string>;
  }

  readBuffer(filepath: string) {
    return (of(null) as unknown) as Observable<ArrayBuffer>;
  }

  install(source: string, target: string) {
    return of(target);
  }

  upload(file: string, target: string, options: FileUploadOptions): Observable<any> {
    return of(null);
  }

  download(source: string, target: string): Observable<FileEntry> {
    return of(({ name: target } as any) as FileEntry);
  }

  extract(file: FileEntry, target: string) {
    return of(target);
  }
}
