import { Injectable } from '@angular/core';
import { log, logResultOrError } from '@app/helpers';
import { FileTransfer, FileUploadOptions } from '@ionic-native/file-transfer/ngx';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Zip } from '@ionic-native/zip/ngx';
import { Platform } from '@ionic/angular';
import { from, Observable, of, throwError } from 'rxjs';
import { map, mapTo, mergeMap, tap, catchError } from 'rxjs/operators';
import { environment } from '@app/env';
import { HttpClient } from '@angular/common/http';
import { Network } from '@awesome-cordova-plugins/network/ngx';

@Injectable()
export class FileService {
  private fs: string;

  constructor(
    // tslint:disable-next-line: deprecation
    private transfer: FileTransfer,
    private file: File,
    private zip: Zip,
    private webview: WebView,
    private platform: Platform,
    private http: HttpClient,
    private network: Network
  ) {
    this.platform.ready().then(() => {
      this.fs =
        environment.production || this.platform.is('ios')
          ? this.file.dataDirectory
          : this.file.externalDataDirectory;
    });
  }

  loadJSON<T = any>(dirName: string, fileName: string): Observable<T> {
    return from(this.file.readAsText(this.fs + dirName, fileName).then((text) => JSON.parse(text)));
  }

  ls(dirName: string) {
    return this.ensureDir(dirName).pipe(mergeMap(() => this.file.listDir(this.fs, dirName)));
  }

  getRoot(dirName: string = '') {
    return this.fs + dirName;
  }

  ensureDir(dirName: string) {
    return from(this.file.checkDir(this.fs, dirName).catch((e) => false)).pipe(
      mergeMap((dirExists) => (dirExists ? of(true) : this.mkDir(dirName).pipe(mapTo(true))))
    );
  }

  toFileUrl(filePath: string) {
    return this.webview.convertFileSrc(filePath).replace(/undefined/gi, 'http://localhost');
  }

  mkDir(dirName: string, replace = false) {
    return from(this.file.createDir(this.fs, dirName, replace).then((dir) => dir.name));
  }

  rmDir(dirName: string) {
    log('rmDir', dirName);
    return from(this.file.checkDir(this.fs, dirName)).pipe(
      catchError(() => of(false)),
      mergeMap((exists) =>
        exists
          ? this.file.removeRecursively(this.fs, dirName).then((res) => res.fileRemoved.name)
          : of(`${dirName} not found`)
      ),
      logResultOrError('removeDir')
    );
  }

  mkFile(fileName: string, replace = false) {
    return from(this.file.createFile(this.fs, fileName, replace).then((file) => file.name));
  }

  rmFile(fileName: string) {
    return from(this.file.checkFile(this.fs, fileName)).pipe(
      catchError(() => of(false)),
      mergeMap((exists) =>
        exists
          ? this.file.removeFile(this.fs, fileName).then((res) => res.fileRemoved.name)
          : of(`${fileName} not found`)
      ),
      logResultOrError('removeFile')
    );
  }

  writeFile(fileName: string, data: string, replace = false) {
    return from(this.file.writeFile(this.fs, fileName, data, { replace }));
  }

  readFile(fileName: string) {
    return from(this.file.readAsText(this.fs, fileName));
  }

  readBuffer(fileName: string) {
    return from(this.file.readAsArrayBuffer(this.fs, fileName));
  }

  install(source: string, target: string) {
    log('install', source);
    return this.download(source, 'tmp.zip').pipe(
      mergeMap((zipFileEntry) => this.extract(zipFileEntry, target)),
      mergeMap((zipFile) => this.rmFile(zipFile)),
      mapTo(target)
    );
  }

  download(source: string, target: string): Observable<FileEntry> {
    if (this.network.type === this.network.Connection.NONE) {
      return throwError(
        new Error('Zum Download von Dateien wird eine Internetverbindung benötigt.')
      );
    }
    // log('download', source, target);
    //   onProgress(listener)
    // Registers a listener that gets called whenever a new chunk of data is transferred.
    // .get(imageUrl, { responseType: ResponseContentType.Blob })
    //         .map((res: Response) => res.blob());
    if (/.*\.png$/.test(source)) {
      log(`downloadImage ${source}`);
      return this.http
        .get(source, {
          responseType: 'blob',
        })
        .pipe(mergeMap((result) => this.file.writeFile(this.fs, target, result)));
    } else {
      return this.rmFile(target).pipe(
        mergeMap(() =>
          this.transfer
            .create()
            .download(source, this.fs + target)
            .then((result) => {
              // debugger;
              return result;
            })
            .catch((e) => {
              // debugger;
              throw e;
            })
        )
      );
    }
  }

  upload(file: string, target: string, options: FileUploadOptions) {
    return from(
      this.transfer.create().upload(
        file,
        target,
        // 'https://ec2-001.aws.freesixtyfive.de/test/',
        // 'https://4sar185na0.execute-api.eu-central-1.amazonaws.com/prod/testendpoint',
        { httpMethod: 'PUT', ...options }
        // { httpMethod: 'PUT', headers: { connection: 'close ' }, chunkedMode: false, ...options }
        // {
        //   httpMethod: 'PUT',
        //   headers: {
        //     'Content-Type': options.mimeType,
        //     // 'Content-Disposition': `attachment; filename="${options.fileName}"`,
        //   },
        // }
      )
    );
  }

  extract(file: FileEntry, target: string) {
    log('extract', [file, target]);
    // , (progress) => log('Unzipping, ' + Math.round((progress.loaded / progress.total) * 100) + '%')
    return from(
      this.zip.unzip(file.toURL(), this.fs + target).then((result) => {
        if (result === -1) {
          throw new Error('Unzip Operation FAILED');
        }
        return file.name;
      })
    );
  }
}
