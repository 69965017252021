import { ensureResult } from '@app/helpers';
import { TriggerEntity, TriggerOfType, TriggerStatus, TriggerTypes } from '@app/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  JourneyActions,
  RouteServiceActions,
  TriggerActions,
  QuestionPopoverActions,
} from '../actions';

const filterByType = <T extends TriggerTypes>(type: T) => (triggers: TriggerEntity[]) =>
  ensureResult(triggers.filter((trigger): trigger is TriggerOfType<T> => trigger.type === type));
const filterByStatus = <T extends TriggerEntity>(status: TriggerStatus) => (triggers: T[]) =>
  ensureResult(triggers.filter((trigger) => trigger.status === status));

export const featureKey = 'triggers';

export const featureAdapter: EntityAdapter<TriggerEntity> = createEntityAdapter<TriggerEntity>({
  sortComparer: false,
});

export interface State extends EntityState<TriggerEntity> {}

export const initialState: State = featureAdapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(
    RouteServiceActions.loadSuccess,
    (state, { triggers }): State => featureAdapter.addMany(triggers, state)
  ),
  on(
    TriggerActions.locationTriggered,
    TriggerActions.stationTriggered,
    TriggerActions.timeTriggered,
    (state, { id }) =>
      featureAdapter.updateOne({ id, changes: { status: TriggerStatus.TRIGGERED } }, state)
  ),
  on(
    QuestionPopoverActions.endChallenge,
    (state, { id }): State =>
      featureAdapter.updateOne({ id, changes: { status: TriggerStatus.DONE } }, state)
  ),
  on(JourneyActions.clearJourney, () => initialState)
);

const { selectAll } = featureAdapter.getSelectors();

const selectFeatureState = createFeatureSelector<State>(featureKey);
const selectAllTriggers = createSelector(selectFeatureState, selectAll);

const selectStationTriggers = createSelector(selectAllTriggers, filterByType(TriggerTypes.STATION));
const selectTimedTriggers = createSelector(selectAllTriggers, filterByType(TriggerTypes.TIME));
const selectLocationTriggers = createSelector(
  selectAllTriggers,
  filterByType(TriggerTypes.GEOPOSITION)
);

export const selectStationTriggersPending = createSelector(
  selectStationTriggers,
  filterByStatus(TriggerStatus.PENDING)
);

export const selectTimedTriggersPending = createSelector(
  selectTimedTriggers,
  filterByStatus(TriggerStatus.PENDING)
);

export const selectLocationTriggersPending = createSelector(
  selectLocationTriggers,
  filterByStatus(TriggerStatus.PENDING)
);

export const selectOptionalTriggersActive = createSelector(
  selectTimedTriggers,
  selectLocationTriggers,
  (timedTriggers, locationTriggers) =>
    filterByStatus(TriggerStatus.TRIGGERED)([...timedTriggers, ...locationTriggers])
);

export const selectStationsTriggersActive = createSelector(
  selectStationTriggers,
  filterByStatus(TriggerStatus.TRIGGERED)
);

export const selectStationTriggersDone = createSelector(
  selectStationTriggers,
  filterByStatus(TriggerStatus.DONE)
);
