import { Injectable } from '@angular/core';
import { LocationService } from '@app/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { defer, merge, NEVER, of, timer } from 'rxjs';
import { catchError, filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  DetailPopoverActions,
  GeolocationActions,
  JourneyActions,
  JourneyPageActions,
} from '../actions';
import * as fromRoot from '../reducers';
import * as fromStore from '../selectors';
import { environment } from '@app/env';
import { getMapBounds } from '@app/helpers';
import { Platform } from '@ionic/angular';
import { LatLngBounds } from 'leaflet';

@Injectable()
export class JourneyEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private locationService: LocationService,
    private platform: Platform
  ) {}

  welcomeJourney$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JourneyPageActions.pageEntered),
      withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectHasStarted)))),
      filter(([_, hasStarted]) => !hasStarted),
      map(() => JourneyPageActions.showWelcome())
    )
  );

  trackJourney$ = createEffect(() =>
    this.store.pipe(
      select(fromStore.selectJourneyTime),
      switchMap(({ isRunning, startedAt, timelimit }) =>
        isRunning
          ? merge(
              this.trackPosition$,
              this.trackTimeout(startedAt, timelimit),
              this.trackStationsDone$
            )
          : NEVER
      )
    )
  );

  selectNextTarget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailPopoverActions.hideDetail, DetailPopoverActions.hideWelcome),
      withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectNextTarget)))),
      filter(([_, target]) => !!target),
      map(([_, target]) => JourneyActions.targetNextStation(target))
    )
  );

  private trackStationsDone$ = defer(() =>
    this.store.pipe(
      select(fromStore.selectHasStations),
      filter((hasStations) => !hasStations),
      first(),
      map(() => JourneyActions.allStationsDone())
    )
  );

  private trackPosition$ = defer(() =>
    environment && environment.emulate && environment.emulate.approach
      ? this.store.pipe(
          select(fromStore.selectMarkers),
          first(),
          map((markers) => {
            const { lat, lng } = getMapBounds(
              markers.reduce(
                (latLngBounds, { id, geoposition }) =>
                  !!id ? latLngBounds.extend(geoposition) : latLngBounds,
                new LatLngBounds([])
              ),
              this.platform.width(),
              this.platform.height()
            ).getCenter();
            return GeolocationActions.positionUpdated({ position: { lat, lng } });
          })
        )
      : this.locationService.location$.pipe(
          map((position) => GeolocationActions.positionUpdated({ position })),
          catchError((error) =>
            of(
              GeolocationActions.positionFailure('Fehler beim Erhalten der GPS Coordinaten', error)
            )
          )
        )
  );

  private trackTimeout(startedAt: number, timelimit: number) {
    return !!timelimit
      ? timer(moment(startedAt).add(timelimit, 'minutes').toDate()).pipe(
          map(() => JourneyActions.journeyTimeout())
        )
      : NEVER;
  }
}
