import { InjectionToken } from '@angular/core';
import { environment } from '@app/env';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { storageSync } from 'ngrx-store-ionic-storage';
import { WebSocketActions } from '../actions';
import * as fromChallenges from './challenges.reducer';
import * as fromChat from './chat.reducer';
import * as fromJourney from './journey.reducer';
import * as fromLogging from './logging.reducer';
import * as fromPages from './pages.reducer';
import * as fromRoute from './route.reducer';
import * as fromStations from './stations.reducer';
import * as fromTriggers from './triggers.reducer';
import { log } from '@app/helpers';

export interface State {
  [fromChallenges.featureKey]: fromChallenges.State;
  [fromChat.featureKey]: fromChat.State;
  [fromJourney.featureKey]: fromJourney.State;
  [fromPages.featureKey]: fromPages.State;
  [fromRoute.featureKey]: fromRoute.State;
  [fromStations.featureKey]: fromStations.State;
  [fromTriggers.featureKey]: fromTriggers.State;
  [fromLogging.featureKey]: fromLogging.State;
}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State, Action>>(
  'Root reducers token',
  {
    factory: () => ({
      [fromChallenges.featureKey]: fromChallenges.reducer,
      [fromChat.featureKey]: fromChat.reducer,
      [fromJourney.featureKey]: fromJourney.reducer,
      [fromPages.featureKey]: fromPages.reducer,
      [fromRoute.featureKey]: fromRoute.reducer,
      [fromStations.featureKey]: fromStations.reducer,
      [fromTriggers.featureKey]: fromTriggers.reducer,
      [fromLogging.featureKey]: fromLogging.reducer,
    }),
  }
);

// Meta Reducers
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    if (!environment.production) {
      log.groupCollapsed(action.type);
      log('prev state', state);
      log('action', action);
      log('next state', result);
      log.groupEnd();
    }
    return result;
  };
}

export const storageSyncReducer = storageSync({
  keys: [
    fromChallenges.featureKey,
    fromChat.featureKey,
    fromJourney.featureKey,
    fromPages.featureKey,
    fromRoute.featureKey,
    fromStations.featureKey,
    fromTriggers.featureKey,
    fromLogging.featureKey,
  ],
  ignoreActions: [
    WebSocketActions.connectSocket.type,
    WebSocketActions.disconnectSocket.type,
    WebSocketActions.socketConnected.type,
    WebSocketActions.socketDisconnected.type,
  ],
  onSyncError: (err: any) => log('[StorageSyncError]', err),
});

export function storageMetaReducer(reducer: ActionReducer<State>): ActionReducer<State> {
  return storageSyncReducer(reducer);
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger, storeFreeze, storageMetaReducer]
  : [storageMetaReducer];
