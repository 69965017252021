import { environment } from '@app/env';
import * as moment from 'moment';
import { mapObjIndexed, toPairs, merge } from 'ramda';
import { Observable, concat } from 'rxjs';
import { tap, catchError, share, take, skip } from 'rxjs/operators';

export const log = (...args: any[]) => {
  if (!environment.production && args.length > 0) {
    const [first, second, ...rest] = args;
    if (args.length < 2) {
      console.log(first);
    } else if (typeof first === 'string' && typeof second !== 'string') {
      console.log(first, second);
      log(...rest);
    } else {
      console.log(first);
      log(second, ...rest);
    }
  }
};

log.groupCollapsed = (...label: any[]) => {
  if (!environment.production) {
    console.groupCollapsed(...label);
  }
};

log.groupEnd = () => {
  if (!environment.production) {
    console.groupEnd();
  }
};

export const logResultOrError =
  (label: string) =>
  <T>(o: Observable<T>) => {
    return o.pipe(
      tap((result) => log(label, result)),
      catchError((error) => {
        log(`${label}Error`, error);
        throw error;
      })
    );
  };

const emptyResult = [];
export const ensureResult: <T>(result: T[], condition?: boolean) => T[] = (
  result,
  condition = true
) => (!!result.length && condition ? result : emptyResult);

export const prependPath = (path: string, url: string) => (/^\.\//.test(url) ? url : path + url);
// export const prependPath = (path: string, url: string) => {
//   log('preprendPath', `BaseURL: ${path}`, `FilePath: ${url || 'NULL'}`);
//   const isLocal = /^\.\//.test(url);
//   const result = isLocal ? url : path + url;
//   log(`isLocal: ${isLocal}`, `ResultURL: ${result || 'NULL'}`);
//   return result;
// };

export const prependPathToProps = <T extends { [K in keyof T]: string }>(root: string, obj: T) =>
  mapObjIndexed((value: string) => (value ? prependPath(root, value) : null), obj) as T;

export const formatDuration = (durationInSeconds: number, alreadyRunInSeconds = 0) =>
  typeof durationInSeconds === 'number' && durationInSeconds > 0
    ? moment.utc(Math.max(durationInSeconds - alreadyRunInSeconds, 0) * 1000).format('HH:mm:ss')
    : null;

export const tapOnce =
  <T>(fn: (v: T) => any) =>
  (source$: Observable<T>) => {
    const sharedSource$ = source$.pipe(share());
    return concat(sharedSource$.pipe(tap(fn), take(1)), sharedSource$.pipe(skip(1)));
  };

export const flattenObj = (obj: { [key: string]: any }) => {
  const _flatten = (tree: any, path: string[] = []) =>
    tree === null || typeof tree !== 'object'
      ? { [path.join('.')]: tree }
      : toPairs(tree).reduce((acc, [key, val]) => merge(acc, _flatten(val, [...path, key])), {});
  return _flatten(obj);
};
