import { createTimestampedAction, props } from '@app/helpers';
import { GeoCoordinates, ChallengeLog, PositionLog } from '@app/models';

enum Action {
  LOGGING_POSITION = '[Logging] Logging Position',
  LOGGING_CHALLENGE = '[Logging] Logging Challenge',
  SENDING_LOG = '[Logging] Sending Log',
  LOG_SEND_SUCCESS = '[Logging] Log Sending Success',
  LOG_SEND_FAILURE = '[Logging] Log Sending Error',
}

export const logPosition = createTimestampedAction(
  Action.LOGGING_POSITION,
  props<GeoCoordinates>()
);

export const logChallenge = createTimestampedAction(
  Action.LOGGING_CHALLENGE,
  props<{ id: string; points: number; success: boolean; position: GeoCoordinates }>()
);

export const sendLog = createTimestampedAction(Action.SENDING_LOG);
export const sendLogSuccess = createTimestampedAction(
  Action.LOG_SEND_SUCCESS,
  props<{ challenges: ChallengeLog[]; positions: PositionLog[] }>()
);
export const sendLogFailure = createTimestampedAction(Action.LOG_SEND_FAILURE);
