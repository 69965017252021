export * from './api.models';
export * from './challenge.model';
export * from './chat.model';
export * from './content.model';
export * from './criteria.model';
export * from './environment.model';
export * from './journey.model';
export * from './location.model';
export * from './page.model';
export * from './route.model';
export * from './station.model';
export * from './trigger.model';
export * from './layout.model';

import { prependPath, prependPathToProps } from '@app/helpers';
import { flatten, omit } from 'ramda';
import { Challenge, ChallengeEntity, ChallengeStatus } from './challenge.model';
import { Page, PageEntity } from './page.model';
import { Route } from './route.model';
import { Station, StationEntity, StationStatus } from './station.model';
import { TriggerEntity, TriggerStatus } from './trigger.model';
import { environment } from '@app/env';
import { LegacyLayout, Layout } from './layout.model';

const getLayoutData = (layout: LegacyLayout | Layout): Layout => {
  if (layout === 'row') {
    return { type: 'column', columns: 2 };
  } else if (layout === 'column') {
    return { type: 'column', columns: 2 };
  } else {
    return layout;
  }
};

const pageDataOf =
  (root: string, parent: string, type: string) =>
  (page: Page, index: number): PageEntity => ({
    ...page,
    layout: getLayoutData(page.layout),
    type,
    parent,
    index,
    contents: page.contents.map((item) => ({
      ...item,
      content:
        item.type === 'text'
          ? item.content
          : item.type === 'image' && !item.content.length
          ? './assets/defaults/preview.png'
          : prependPath(root, item.content),
    })),
  });

const getPagesData = (
  root: string,
  welcome: Page[],
  stations: Station[],
  challenges: Challenge[]
) =>
  flatten([
    welcome.map(pageDataOf(root, 'welcome', 'welcome')),
    stations.map(({ id, pages }) => pages.map(pageDataOf(root, id, 'page'))),
    challenges.map(({ id, pages, failure, success }) => [
      pages.map(pageDataOf(root, id, 'page')),
      failure.map(pageDataOf(root, id, 'failure')),
      success.map(pageDataOf(root, id, 'success')),
    ]),
  ]);

const getTriggersData = (challenges: Challenge[]) =>
  challenges.map(
    (challenge: Challenge): TriggerEntity => ({
      id: challenge.id,
      status: TriggerStatus.PENDING,
      ...challenge.trigger,
    })
  );

const getChallengesData = (challenges: Challenge[]) =>
  challenges.map(
    (challenge: Challenge) =>
      ({
        ...omit(['pages', 'success', 'failure', 'trigger'], challenge),
        timestamp: null,
        status: ChallengeStatus.OPEN,
      } as ChallengeEntity)
  );

const getStationsData = (root: string, { stations }: Route) =>
  stations.map(
    ({ id, title, marker, preview, geoposition }: Station): StationEntity => ({
      id,
      title,
      preview: { ...preview, image: prependPath(root, preview.image) },
      geoposition,
      marker: prependPathToProps(root, marker),
      status: StationStatus.OPEN,
    })
  );

const getRouteData = (
  root: string,
  { info: { title, titleInternal, startText }, mode, sounds }: Route
) => ({
  title,
  titleInternal: titleInternal || title,
  startText,
  ...mode,
  sounds: prependPathToProps(root, sounds),
});

export const journeyOf = (root: string, route: Route) => {
  const usedChallenges = route.challenges.filter(
    (challenge) =>
      environment.native || environment.feature.preview || challenge.type !== 'createmedia'
  );

  return {
    route: getRouteData(root, route),
    stations: getStationsData(root, route),
    challenges: getChallengesData(usedChallenges),
    triggers: getTriggersData(usedChallenges),
    pages: getPagesData(root, route.welcome, route.stations, usedChallenges),
  };
};

export type JourneyData = ReturnType<typeof journeyOf>;
