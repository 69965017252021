import { Injectable } from '@angular/core';
import { distanceOf, StationTriggerEntity, TriggerStatus } from '@app/models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { find, propEq } from 'ramda';
import { combineLatest, defer, from, NEVER, timer, of } from 'rxjs';
import { filter, first, map, mergeMap, switchMap, withLatestFrom, concatMap } from 'rxjs/operators';
import {
  DetailPopoverActions,
  GeolocationActions,
  TriggerActions,
  JourneyActions,
} from '../actions';
import * as fromRoot from '../reducers';
import * as fromStore from '../selectors';

@Injectable()
export class TriggerEffects {
  constructor(private actions$: Actions, private store: Store<fromRoot.State>) {}

  stationTrigger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailPopoverActions.hideDetail),
      withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectStationTriggersPending)))),
      map(([{ id }, triggers]) => find<StationTriggerEntity>(propEq('station', id), triggers)),
      filter((trigger) => !!trigger),
      concatMap(({ id }) => [TriggerActions.stationTriggered(id), TriggerActions.showChallenge(id)])
    )
  );

  // triggerStationChallenge$ = createEffect(() =>
  //   this.store.pipe(
  //     select(fromStore.selectStationTriggerActive),
  //     filter((trigger) => !!trigger),
  //     map(({ id }) => TriggerActions.showChallenge(id))
  //   )
  // );

  // stationsDoneTrigger$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(JourneyActions.allStationsDone),
  //     withLatestFrom(
  //       defer(() =>
  //         combineLatest([
  //           this.store.pipe(select(fromStore.selectTimeout)),
  //           this.store.pipe(select(fromStore.selectTimeTriggersPending)),
  //         ])
  //       )
  //     ),
  //     concatMap(([, [timeout, pending]]) =>
  //       timeout ? NEVER : pending.map((trigger) => TriggerActions.timeTriggered(trigger.id))
  //     )
  //   )
  // );

  timeTrigger$ = createEffect(() =>
    combineLatest([
      this.store.pipe(select(fromStore.selectStartedAt)),
      this.store.pipe(select(fromStore.selectIsRunning)),
      this.store.pipe(select(fromStore.selectTimeTriggersPending)),
    ]).pipe(
      filter(([startedAt]) => !!startedAt),
      switchMap(([startedAt, isRunning, triggers]) =>
        isRunning && !!triggers.length
          ? from(triggers).pipe(
              mergeMap((trigger) =>
                timer(moment(startedAt).add(trigger.minutes, 'minutes').toDate()).pipe(
                  first(),
                  map(() => TriggerActions.timeTriggered(trigger.id))
                )
              )
            )
          : NEVER
      )
    )
  );

  locationTrigger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeolocationActions.positionUpdated),
      withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectLocationTriggersPending)))),
      mergeMap(([{ position }, triggers]) =>
        triggers.reduce(
          (actions, trigger) =>
            distanceOf(position, trigger.geoposition).isNear
              ? [...actions, TriggerActions.locationTriggered(trigger.id)]
              : actions,
          []
        )
      )
    )
  );
}
