import { GeoCoordinates, Rank } from '@app/models';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  RankingApiActions,
  GeolocationActions,
  HomePageActions,
  JourneyActions,
  JourneyPageActions,
  RouteServiceActions,
  ChallengeActions,
} from '../actions';

export const featureKey = 'journey';

export interface State {
  voucherCode: string;
  customer: string;
  session: string;
  startedAt: number;
  endedAt: number;
  timeout: boolean;
  position: GeoCoordinates;
  points: number;
  ranking: Rank[];
}

export const initialState: State = {
  voucherCode: null,
  customer: null,
  session: null,
  startedAt: null,
  endedAt: null,
  timeout: false,
  position: null,
  points: 0,
  ranking: [],
};

export const reducer = createReducer(
  initialState,
  on(
    HomePageActions.entered,
    (state, { code, customer }): State => ({ ...state, voucherCode: code, customer })
  ),
  on(
    RouteServiceActions.startSuccess,
    (state, { response: { id } }): State => ({
      ...state,
      session: id,
    })
  ),
  on(GeolocationActions.positionUpdated, (state, { position }): State => ({ ...state, position })),
  on(
    JourneyPageActions.startJourney,
    (state, { timestamp }): State => ({
      ...state,
      startedAt: timestamp,
    })
  ),
  on(JourneyActions.allStationsDone, (state, { timestamp }) => ({
    ...state,
    endedAt: timestamp,
  })),
  on(JourneyActions.journeyTimeout, (state): State => ({ ...state, timeout: true })),
  on(RankingApiActions.loadSuccess, (state, { ranking }): State => ({ ...state, ranking })),
  on(
    ChallengeActions.challengeSucceeded,
    (state, { points }): State => ({ ...state, points: state.points + points })
  ),
  on(JourneyActions.clearJourney, () => initialState)
);

export const selectFeatureState = createFeatureSelector<State>(featureKey);
export const selectSession = createSelector(selectFeatureState, (state) => state.session);
export const selectStartedAt = createSelector(selectFeatureState, (state) => state.startedAt);
export const selectEndedAt = createSelector(selectFeatureState, (state) => state.endedAt);
export const selectPosition = createSelector(selectFeatureState, (state) => state.position);
export const selectPoints = createSelector(selectFeatureState, (state) => state.points);
export const selectVoucherCode = createSelector(selectFeatureState, (state) => state.voucherCode);
export const selectCustomer = createSelector(selectFeatureState, (state) => state.customer);

export const selectHasStarted = createSelector(selectStartedAt, (startedAt) => !!startedAt);
export const selectHasEnded = createSelector(selectEndedAt, (endedAt) => !!endedAt);
export const selectTimeout = createSelector(selectFeatureState, (state) => state.timeout);

export const selectRanking = createSelector(selectFeatureState, (state) => state.ranking || []);

export const selectIsRunning = createSelector(
  selectHasStarted,
  selectHasEnded,
  (hasStarted, hasEnded) => hasStarted && !hasEnded
);
