import { createTimestampedAction, createIdAction, props } from '@app/helpers';

enum Action {
  PAGE_ENTERED = '[Journey Page] Page Entered',
  CONFIRM_ABORT = '[Passsword Page] Confirm Journey Abort',
  START_JOURNEY = '[Journey Page] Start Journey',
  ABORT_JOURNEY = '[Journey Page] Abort Journey',
  END_JOURNEY = '[Journey Page] End Journey',
  SHOW_WELCOME = '[Journey Page] Show Welcome',
  SHOW_PREVIEW = '[Journey Page] Show Preview',
  SHOW_CHALLENGE = '[Journey Page] Show Challenge',
  SHOW_SCORE = '[Journey Page] Show Score',
  RELOAD_SCORE = '[Score Overlay] Reload Score',
  SEND_LOGS = '[Journey Page] Send Logs',
}

export const pageEntered = createTimestampedAction(Action.PAGE_ENTERED);
export const confirmAbort = createTimestampedAction(Action.CONFIRM_ABORT);
export const startJourney = createTimestampedAction(Action.START_JOURNEY);
export const abortJourney = createTimestampedAction(Action.ABORT_JOURNEY);
export const endJourney = createTimestampedAction(Action.ABORT_JOURNEY);

export const showWelcome = createTimestampedAction(Action.SHOW_WELCOME);
export const showScore = createTimestampedAction(Action.SHOW_SCORE);
export const sendLogs = createTimestampedAction(Action.SEND_LOGS);
export const reloadScore = createTimestampedAction(Action.RELOAD_SCORE);

export const showChallenge = createIdAction(Action.SHOW_CHALLENGE);

export const showPreview = createTimestampedAction(
  Action.SHOW_PREVIEW,
  props<{ id: string; isOverview: boolean }>()
);
