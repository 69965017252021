import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { File } from '@ionic-native/file/ngx';
import { CacheService } from '../services/cache.service';

@Injectable()
export class LogErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error) {
    const cache = this.injector.get(CacheService);
    const file = this.injector.get(File);
    const time = Date.now();
    const { action, prevState, nextState } = cache.getCache();

    Promise.all([
      file.writeFile(file.externalDataDirectory, `${time}_error.log`, error.stack),
      file.writeFile(file.externalDataDirectory, `${time}_action.json`, JSON.stringify(action)),
      file.writeFile(file.externalDataDirectory, `${time}_prev.json`, JSON.stringify(prevState)),
      file.writeFile(file.externalDataDirectory, `${time}_next.json`, JSON.stringify(nextState)),
    ]);

    throw error;
  }
}
