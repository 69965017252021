import { JourneyParams, RouteMode } from '@app/models';
import { createTimestampedAction, props, createErrorAction } from '@app/helpers';

enum Action {
  ROUTE_SELECTED = '[Manage Page] Route Selected',
  MODE_SELECTED = '[Manage Page] Mode Selected',
  TEAM_SELECTED = '[Manage Page] Team Selected',
  LOGGING_SELECTED = '[Manage Page] Logging Selected',
  LOAD_FAILURE = '[Manage Page] Load Routelist Failure',
  UPDATE_FAILURE = '[Manage Page] Update Routelist Failure',
  RESET_FAILURE = '[Manage Page] Reset Routelist Failure',
}

export const routeSelected = createTimestampedAction(
  Action.ROUTE_SELECTED,
  props<{ options: Partial<JourneyParams> }>()
);

export const modeSelected = createTimestampedAction(
  Action.MODE_SELECTED,
  props<{ mode: RouteMode }>()
);

export const loggingSelected = createTimestampedAction(
  Action.LOGGING_SELECTED,
  props<{ logSend: number; logWrite: number }>()
);

export const teamSelected = createTimestampedAction(
  Action.TEAM_SELECTED,
  props<{ options: Partial<JourneyParams> }>()
);

export const loadListFailure = createErrorAction(Action.LOAD_FAILURE);
export const updateListFailure = createErrorAction(Action.UPDATE_FAILURE);
export const resetListFailure = createErrorAction(Action.RESET_FAILURE);
