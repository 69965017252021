import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator, Store } from '@ngrx/store';
import { Howl } from 'howler';
import { defer, merge, NEVER, Observable } from 'rxjs';
import { mapTo, switchMap, tap, take } from 'rxjs/operators';
import { ChallengeActions, TriggerActions, WebSocketActions } from '../actions';
import * as fromRoot from '../reducers';
import * as fromStore from '../selectors';

@Injectable()
export class SoundEffects {
  constructor(private actions$: Actions, private store: Store<fromRoot.State>) {}

  initSounds$ = createEffect(
    () =>
      defer(() =>
        this.store.select(fromStore.selectSounds).pipe(
          take(1),
          switchMap(
            ({
              challengeSuccess,
              challengeFailure,
              chatNotification,
              newNonLinearQuestion,
              // openStationFailure,
              // openStationSuccess,
            }) =>
              merge(
                // this.initSound(openStationFailure, ChallengeActions.challengeSucceeded ),
                // this.initSound(openStationSuccess, ChallengeActions.challengeSucceeded),
                this.initSound(challengeSuccess, ChallengeActions.challengeSucceeded),
                this.initSound(challengeFailure, ChallengeActions.challengeFailed),
                this.initSound(chatNotification, WebSocketActions.messageReceived),
                this.initSound(
                  newNonLinearQuestion,
                  TriggerActions.timeTriggered,
                  TriggerActions.locationTriggered
                )
              ).pipe(tap((sound) => sound.play()))
          )
        )
      ),
    { dispatch: false }
  );

  initSound<T extends ActionCreator>(sound: string, ...actions: T[]): Observable<Howl> {
    return typeof sound === 'string'
      ? this.actions$.pipe(
          ofType(...actions),
          mapTo(
            new Howl({
              src: [sound],
            })
          )
        )
      : NEVER;
  }
}
