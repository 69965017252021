import { Injectable } from '@angular/core';
import { ApiService } from '@app/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { defer, interval, merge, of, NEVER } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
  mapTo,
} from 'rxjs/operators';
import { ChallengeActions, LoggingActions, JourneyActions, JourneyPageActions } from '../actions';
import * as fromRoot from '../reducers';
import * as fromStore from '../selectors';

const toMs = 60000;

@Injectable()
export class LoggingEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private api: ApiService
  ) {}

  trackLogging$ = createEffect(() =>
    defer(() =>
      this.store.pipe(
        select(fromStore.selectLogConfig),
        switchMap(({ isRunning, send, write }) =>
          isRunning
            ? merge(this.trackChallenges$, this.sendLog(send), this.trackPosition(write))
            : NEVER
        )
      )
    )
  );

  flushJourney$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JourneyPageActions.sendLogs, JourneyActions.flushJourney),
      withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectLog)))),
      mergeMap(([_, log]) =>
        this.api.update('journeys', log.session, log).pipe(
          map(() => LoggingActions.sendLogSuccess(log)),
          catchError((e) => of(LoggingActions.sendLogFailure()))
        )
      )
    )
  );

  private trackChallenges$ = this.actions$.pipe(
    ofType(ChallengeActions.challengeSucceeded, ChallengeActions.challengeFailed),
    withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectPosition)))),
    map(([action, position]) =>
      LoggingActions.logChallenge({
        id: action.id,
        position,
        points: action.type === ChallengeActions.challengeSucceeded.type ? action.points : 0,
        success: action.type === ChallengeActions.challengeSucceeded.type ? true : false,
      })
    )
  );

  private sendLog(minutes: number) {
    return !minutes
      ? NEVER
      : interval(minutes * toMs).pipe(
          withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectLog)))),
          mergeMap(([_, log]) =>
            this.api.update('journeys', log.session, log).pipe(
              map(() => LoggingActions.sendLogSuccess(log)),
              catchError((e) => of(LoggingActions.sendLogFailure()))
            )
          )
        );
  }

  private trackPosition(minutes: number) {
    return interval(minutes * toMs).pipe(
      withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectPosition)))),
      filter(([_, position]) => !!position && !!position.lat && !!position.lng),
      map(([_, position]) => LoggingActions.logPosition(position))
    );
  }
}
