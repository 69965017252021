import { Injectable } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { log } from '@app/helpers';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { StorageSyncActions } from 'ngrx-store-ionic-storage';
import { defer, zip, of } from 'rxjs';
import { filter, map, tap, withLatestFrom, mergeMap } from 'rxjs/operators';
import {
  HomePageActions,
  JourneyActions,
  JourneyPageActions,
  RouteServiceActions,
  RoutesPageActions,
} from '../actions';
import * as fromRoot from '../reducers';
import * as fromStore from '../selectors';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private navCtrl: NavController,
    private router: Router
  ) {}

  invalidateVoucher = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HomePageActions.validationError),
        tap(() => this.navCtrl.navigateRoot('/home/voucher-code-error'))
      ),
    { dispatch: false }
  );

  goToRoutesPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HomePageActions.selectRoute),
        tap(({ customer }) => this.navCtrl.navigateRoot(`/routes/${customer}`))
      ),
    { dispatch: false }
  );

  setupRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoutesPageActions.routeSelected),
        // withLatestFrom(this.actions$.pipe(ofType(RouteServiceActions.loadSuccess))),
        tap(() => this.navCtrl.navigateRoot('/manage'))
      ),
    {
      dispatch: false,
    }
  );

  beginJourney$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouteServiceActions.startSuccess, StorageSyncActions.HYDRATED),
        withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectSession)))),
        filter(([_, session]) => !!session),
        tap(() => this.navCtrl.navigateRoot('/journey'))
      ),
    {
      dispatch: false,
    }
  );

  endJourney$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JourneyPageActions.endJourney),
      map(() => JourneyActions.flushJourney({ navigateTo: `/` }))
    )
  );

  leaveJourney$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JourneyActions.flushJourney),
      mergeMap(({ navigateTo }) =>
        of(JourneyActions.clearJourney()).pipe(tap(() => this.navCtrl.navigateRoot(navigateTo)))
      )
    )
  );

  secureJourney$ = createEffect(
    () =>
      defer(() =>
        this.router.events.pipe(
          filter((e) => e instanceof ActivationStart && e.snapshot.routeConfig.path === 'journey'),
          withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectSession)))),
          filter(([_, session]) => !session),
          tap(() => {
            log('journey Secured');
            this.navCtrl.navigateRoot('/');
          })
        )
      ),
    { dispatch: false }
  );

  configWebApp$ = createEffect(() =>
    defer(() =>
      zip(
        this.router.events.pipe(
          filter(
            (e): e is ActivationStart =>
              e instanceof ActivationStart &&
              !!e.snapshot.queryParams.voucher_code &&
              !!e.snapshot.params.customerId
          )
        ),
        this.actions$.pipe(ofType(StorageSyncActions.HYDRATED))
      ).pipe(
        map(([e]) =>
          HomePageActions.entered({
            code: e.snapshot.queryParams.voucher_code,
            customer: e.snapshot.params.customerId,
          })
        )
      )
    )
  );
}

// this.router.events.pipe(
//   filter(
//     (e): e is ActivationStart =>
//       e instanceof ActivationStart &&
//       !!e.snapshot.queryParams.voucher_code &&
//       !!e.snapshot.params.customerId
//   ),
//   sample(this.actions$.pipe(ofType(StorageSyncActions.HYDRATED))),
