import { Injectable } from '@angular/core';
import { FileService } from './file.service';
import { ReplaySubject, of, from } from 'rxjs';
import { Platform } from '@ionic/angular';
import { environment } from '@app/env';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  private customerToken$ = new ReplaySubject<string>(1);
  public token$ = this.customerToken$.asObservable();

  constructor(private platform: Platform, private file: FileService) {
    this.readToken();
  }

  readToken() {
    if (environment.native) {
      from(this.platform.ready())
        .pipe(
          mergeMap(() => this.file.readFile('customer.token')),
          catchError(() => of(null))
        )
        .subscribe((token) => {
          this.customerToken$.next(
            !token && environment.emulate && typeof environment.emulate.device === 'string'
              ? environment.emulate.device
              : token
          );
        });
    } else if (!!environment.feature.route) {
      this.customerToken$.next(environment.emulate.device as string);
    } else {
      this.customerToken$.next('noToken');
    }
  }

  setToken(token: string) {
    this.file.writeFile('customer.token', token, true).subscribe(() => {
      this.customerToken$.next(token);
    });
  }
}

// function ab2str(buf) {
//   return ;
// }
// function str2ab(str) {
//   var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
//   var bufView = new Uint16Array(buf);
//   for (var i = 0, strLen = str.length; i < strLen; i++) {
//     bufView[i] = str.charCodeAt(i);
//   }
//   return buf;
// }
