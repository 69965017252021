import { createFeatureSelector, createReducer, on, createSelector } from '@ngrx/store';
import { RouteMode, JourneyParams, RouteSounds } from '@app/models';

import { environment } from '@app/env';
import {
  RouteServiceActions,
  ManagePageActions,
  RoutesPageActions,
  JourneyActions,
} from '../actions';

export const featureKey = 'route';

export interface State {
  route: string;
  team: string;
  group: string;
  title: string;
  titleInternal: string;
  startText: string;
  linear: boolean;
  challenges: boolean;
  chat: boolean;
  timelimit: number;
  logSend: number;
  logWrite: number;
  sounds: RouteSounds;
}

export const initialState: State = {
  route: null,
  team: 'default',
  group: 'default',
  title: null,
  titleInternal: null,
  startText: null,
  linear: null,
  challenges: null,
  chat: environment.feature.chat,
  timelimit: null,
  logSend: null,
  logWrite: null,
  sounds: {
    challengeSuccess: null,
    challengeFailure: null,
    openStationFailure: null,
    openStationSuccess: null,
    chatNotification: null,
    newNonLinearQuestion: null,
  },
};

export const reducer = createReducer(
  initialState,
  on(
    ManagePageActions.routeSelected,
    RoutesPageActions.routeSelected,
    ManagePageActions.teamSelected,
    (state, { options }): State => ({
      ...state,
      ...options,
    })
  ),
  on(
    RouteServiceActions.loadSuccess,
    (state, { route }): State => ({
      ...state,
      ...route,
      sounds: { ...state.sounds, ...route.sounds },
    })
  ),
  on(
    ManagePageActions.modeSelected,
    (state, { mode }): State => ({
      ...state,
      ...mode,
    })
  ),
  on(
    RouteServiceActions.startSuccess,
    (state, { response: { logSend = null, logWrite = null } }): State => ({
      ...state,
      logSend,
      logWrite,
    })
  ),
  on(JourneyActions.clearJourney, () => initialState)
);

export const selectFeatureState = createFeatureSelector<State>(featureKey);
export const selectTitle = createSelector(selectFeatureState, (state) => state.title);
export const selectTitleInternal = createSelector(
  selectFeatureState,
  (state) => state.titleInternal
);
export const selectRoute = createSelector(selectFeatureState, (state) => state.route);
export const selectTeam = createSelector(selectFeatureState, (state) => state.team);
export const selectGroup = createSelector(selectFeatureState, (state) => state.group);
export const selectStartText = createSelector(selectFeatureState, (state) => state.startText);
export const selectUseTimelimit = createSelector(selectFeatureState, (state) => state.timelimit);
export const selectUseChallenges = createSelector(selectFeatureState, (state) => state.challenges);
export const selectUseLinear = createSelector(selectFeatureState, (state) => state.linear);
export const selectUseChat = createSelector(selectFeatureState, (state) => state.chat);
export const selectSounds = createSelector(selectFeatureState, (state) => state.sounds);

export const selectMode = createSelector(
  selectUseLinear,
  selectUseChallenges,
  selectUseChat,
  selectUseTimelimit,
  (linear, challenges, chat, timelimit): RouteMode => ({
    linear,
    challenges,
    chat,
    timelimit,
  })
);
export const selectOptions = createSelector(
  selectRoute,
  selectTeam,
  selectGroup,
  (route, team, group): JourneyParams => ({
    route,
    team,
    group,
  })
);
