import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '@app/env';
import { fromStore, RootState } from '@app/root-store';
import { ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPopoverComponent {
  @Input() station: string;
  @Input() isOverview: boolean;
  public preview$ = this.store.pipe(select(fromStore.selectPreviewPage));
  public debugMode = environment.emulate && environment.emulate.approach;

  constructor(private modalCtrl: ModalController, private store: Store<RootState>) {}

  openViewer(src: string) {
    this.modalCtrl.dismiss({ src }, 'image-viewer');
  }

  close(showDetail = false) {
    if (showDetail) {
      this.modalCtrl.dismiss({ station: this.station }, 'show-detail');
    } else {
      this.modalCtrl.dismiss(null, 'hide-preview');
    }
  }
}
