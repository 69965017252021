import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { CriteriaDisplay, MediaTypes, Content } from '@app/models';
import { ChallengeActions, RootState } from '@app/root-store';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { Store } from '@ngrx/store';
import { ChallengeComponent } from '../challenge-component.interface';
import { log } from '@app/helpers';

const mimeType = {
  video: { dataUri: 'data:video/mp4;base64,', ext: '.mp4' },
  image: { dataUri: 'data:image/jpg;base64,', ext: '.jpg' },
};

@Component({
  selector: 'app-createmedia-challenge',
  templateUrl: './createmedia-challenge.component.html',
  styleUrls: ['./createmedia-challenge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatemediaChallengeComponent implements OnInit, ChallengeComponent {
  @Input() id: string;
  @Input() mediaType: MediaTypes;
  @Input() status: CriteriaDisplay;
  @Output() challengeAttempt = new EventEmitter<boolean>();

  public showPreview = false;
  public preview: Content = null;

  constructor(
    private cameraPreview: CameraPreview,
    private store: Store<RootState>,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.cameraPreview.stopCamera().catch((e) => {});
  }

  solve() {
    this.store.dispatch(
      ChallengeActions.mediaCreated({
        base64: this.preview.content,
        filename: this.id + mimeType[this.mediaType].ext,
      })
    );
    this.challengeAttempt.emit(true);
  }

  takePicture() {
    this.cameraPreview
      .takePicture()
      .then(([data]: string[]) => {
        this.preview = { type: this.mediaType, content: mimeType[this.mediaType].dataUri + data };
        this.togglePreview(false);
      })
      .catch((error) => {
        this.togglePreview(false);
        this.store.dispatch(
          ChallengeActions.mediaError(
            `${this.mediaType === 'image' ? 'Bild' : 'Video'} konnte nicht erstellt werden`,
            error
          )
        );
        this.challengeAttempt.emit(false);
      });
  }

  switchCamera() {
    this.cameraPreview
      .switchCamera()
      .catch((e) => ChallengeActions.mediaError('Kamera konnte nicht gewechselt werden', e));
  }

  togglePreview(show: boolean) {
    if (show) {
      this.preview = null;
      (window.document.querySelector('body') as HTMLElement).classList.add('cameraView');
      this.cameraPreview
        .startCamera({
          camera: this.cameraPreview.CAMERA_DIRECTION.BACK,
          tapPhoto: false,
          toBack: true,
          tapFocus: true,
          disableExifHeaderStripping: true,
        })
        .then((r) => log(r))
        .catch((e) => log(e));
    } else {
      (window.document.querySelector('body') as HTMLElement).classList.remove('cameraView');
      this.cameraPreview.stopCamera().catch((e) => log(e));
    }
    this.showPreview = show;
    this.cd.detectChanges();
  }
}
