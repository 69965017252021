import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DetailPopoverActions, fromStore, RootState } from '@app/root-store';
import { ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'app-detail-popover',
  templateUrl: './detail-popover.component.html',
  styleUrls: ['./detail-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailPopoverComponent {
  @Input() id: string = null;
  public detail$ = this.store.pipe(select(fromStore.selectDetailPage));

  constructor(private modalCtrl: ModalController, private store: Store<RootState>) {}

  nextPage(hasNext: boolean) {
    if (hasNext) {
      this.store.dispatch(DetailPopoverActions.nextPage());
    } else {
      this.modalCtrl.dismiss({ id: this.id }, this.id !== null ? 'hide-detail' : 'hide-welcome');
    }
  }

  prevPage() {
    this.store.dispatch(DetailPopoverActions.prevPage());
  }
}
