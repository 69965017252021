import { createErrorAction, createTimestampedAction, props } from '@app/helpers';
import { JourneyData, JourneyResponse } from '@app/models';

enum Action {
  LOAD_SUCCESS = '[Route Service] Load Success',
  LOAD_FAILURE = '[Route Service] Load Failure',
  START_SUCCESS = '[Route Service] Start Success',
  START_FAILURE = '[Route Service] Start Failure',
}

export const loadFailure = createErrorAction(Action.LOAD_FAILURE);
export const startFailure = createErrorAction(Action.START_FAILURE);

export const loadSuccess = createTimestampedAction(Action.LOAD_SUCCESS, props<JourneyData>());

export const startSuccess = createTimestampedAction(
  Action.START_SUCCESS,
  props<{ response: JourneyResponse }>()
);
