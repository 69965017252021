import { PageEntity } from '@app/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  DetailPopoverActions,
  JourneyPageActions,
  PreviewPopoverActions,
  RouteServiceActions,
  TriggerActions,
  ChallengeActions,
  QuestionPopoverActions,
  JourneyActions,
} from '../actions';

export const featureKey = 'pages';

export const featureAdapter: EntityAdapter<PageEntity> = createEntityAdapter<PageEntity>({
  selectId: ({ parent, type, index }) => `${parent}|${type}|${index}`,
  sortComparer: false,
});

export interface State extends EntityState<PageEntity> {
  selectedParent: string;
  selectedIndex: number;
  selectedType: string;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedParent: null,
  selectedIndex: null,
  selectedType: null,
});

export const reducer = createReducer(
  initialState,
  on(
    RouteServiceActions.loadSuccess,
    (state, { pages }): State => featureAdapter.addMany(pages, state)
  ),
  on(
    JourneyPageActions.showWelcome,
    JourneyPageActions.showChallenge,
    TriggerActions.showChallenge,
    PreviewPopoverActions.showDetail,
    (state, action): State => ({
      ...state,
      selectedIndex: 0,
      selectedType: 'id' in action ? 'page' : 'welcome',
      selectedParent: 'id' in action ? action.id : 'welcome',
    })
  ),
  on(
    DetailPopoverActions.hideDetail,
    DetailPopoverActions.hideWelcome,
    QuestionPopoverActions.endChallenge,
    (state): State => ({
      ...state,
      selectedIndex: null,
      selectedType: null,
      selectedParent: null,
    })
  ),
  on(
    DetailPopoverActions.nextPage,
    QuestionPopoverActions.nextPage,
    (state): State => ({ ...state, selectedIndex: state.selectedIndex + 1 })
  ),
  on(
    DetailPopoverActions.prevPage,
    QuestionPopoverActions.prevPage,
    (state): State => ({ ...state, selectedIndex: state.selectedIndex - 1 })
  ),
  on(ChallengeActions.challengeSucceeded, (state) => ({
    ...state,
    selectedIndex: 0,
    selectedType: 'success',
  })),
  on(ChallengeActions.challengeFailed, (state) => ({
    ...state,
    selectedIndex: 0,
    selectedType: 'failure',
  })),
  on(JourneyActions.clearJourney, () => initialState)
);

const { selectEntities } = featureAdapter.getSelectors();
const selectId = (parent: string, type: string, index: number) =>
  featureAdapter.selectId({ parent, type, index } as PageEntity);

const selectFeatureState = createFeatureSelector<State>(featureKey);
const selectPageEntities = createSelector(selectFeatureState, selectEntities);

export const selectCurrentPageIndex = createSelector(
  selectFeatureState,
  (state) => state.selectedIndex
);

export const selectCurrentPageType = createSelector(
  selectFeatureState,
  (state) => state.selectedType
);

export const selectCurrentParent = createSelector(
  selectFeatureState,
  (state) => state.selectedParent
);

export const selectCurrentPage = createSelector(
  selectPageEntities,
  selectCurrentPageIndex,
  selectCurrentPageType,
  selectCurrentParent,
  (pageEntities, index, type, parent) => pageEntities[selectId(parent, type, index)] || null
);

export const selectPagination = createSelector(
  selectPageEntities,
  selectCurrentPage,
  (pageEntities, page) =>
    page && {
      hasPrev: !!pageEntities[selectId(page.parent, page.type, page.index - 1)],
      hasNext: !!pageEntities[selectId(page.parent, page.type, page.index + 1)],
      prevText: page.prevText,
      nextText: page.nextText,
    }
);
