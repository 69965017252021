import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { AppHeaderComponent } from './app-header/app-header.component';
import { ContentComponent } from './content/content.component';
import { DetailPopoverComponent } from './detail-popover/detail-popover.component';
import { InfoPopoverComponent } from './info-popover/info-popover.component';
import { PageComponent } from './page/page.component';
import { QuestionPopoverComponent } from './question-popover/question-popover.component';
import { ScorePopoverComponent } from './score-popover/score-popover.component';
import { PaginationComponent } from './pagination/pagination.component';
import { QuestionChallengeComponent } from './challenges/question-challenge/question-challenge.component';
import { SinglechoiceChallengeComponent } from './challenges/singlechoice-challenge/singlechoice-challenge.component';
import { CreatemediaChallengeComponent } from './challenges/createmedia-challenge/createmedia-challenge.component';
import { PreviewPopoverComponent } from './preview-popover/preview-popover.component';

const entryComponents = [
  DetailPopoverComponent,
  InfoPopoverComponent,
  QuestionPopoverComponent,
  ScorePopoverComponent,
  PreviewPopoverComponent,
];

const exports = [
  AppHeaderComponent,
  PageComponent,
  PaginationComponent,
  QuestionChallengeComponent,
  SinglechoiceChallengeComponent,
  CreatemediaChallengeComponent,
  ContentComponent,
];

const declarations = [...exports, ...entryComponents];

@NgModule({
  declarations,
  imports: [CommonModule, FormsModule, NgxIonicImageViewerModule, IonicModule],
  exports,
  entryComponents,
})
export class ComponentsModule {}
