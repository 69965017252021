import { createIdAction } from '@app/helpers';

enum Action {
  STATION_TRIGGER = '[Station Trigger] Station Visited',
  SHOW_CHALLENGE = '[Station Trigger] Show Challenge',
  TIME_TRIGGER = '[Time Trigger] Time Elapsed',
  LOCATION_TRIGGER = '[Location Trigger] Location Reached',
}

export const showChallenge = createIdAction(Action.SHOW_CHALLENGE);
export const stationTriggered = createIdAction(Action.STATION_TRIGGER);
export const timeTriggered = createIdAction(Action.TIME_TRIGGER);
export const locationTriggered = createIdAction(Action.LOCATION_TRIGGER);
