import { ChatMessage } from '@app/models';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ChatPageActions, JourneyActions, WebSocketActions } from '../actions';

export const featureKey = 'chat';

export interface State {
  messages: ChatMessage[];
  new: number;
  isOpen: boolean;
  isConnected: boolean;
  operatorTyping: boolean;
}

export const initialState: State = {
  messages: [],
  new: 0,
  isOpen: false,
  isConnected: false,
  operatorTyping: false,
};

export const reducer = createReducer(
  initialState,
  on(WebSocketActions.socketConnected, (state) => ({ ...state, isConnected: true })),
  on(WebSocketActions.socketDisconnected, (state) => ({ ...state, isConnected: false })),
  on(WebSocketActions.operatorTyping, (state, { typing }) => ({
    ...state,
    operatorTyping: typing,
  })),
  on(WebSocketActions.messageReceived, (state, { message }) => ({
    ...state,
    messages: [...state.messages, message],
    new: state.isOpen ? state.new : state.new + 1,
  })),
  on(ChatPageActions.sendMessage, (state, { message }) => ({
    ...state,
    messages: [...state.messages, message],
  })),
  on(ChatPageActions.chatOpened, (state) => ({ ...state, isOpen: true, new: 0 })),
  on(ChatPageActions.chatClosed, (state) => ({ ...state, isOpen: false })),
  on(JourneyActions.clearJourney, () => initialState)
);

export const selectFeatureState = createFeatureSelector<State>(featureKey);
export const selectChatMessages = createSelector(selectFeatureState, (state) => state.messages);
export const selectChatNewMessageCount = createSelector(selectFeatureState, (state) => state.new);
export const selectChatOperatorTyping = createSelector(
  selectFeatureState,
  (state) => state.operatorTyping
);
export const selectSocketIsConnected = createSelector(
  selectFeatureState,
  (state) => state.isConnected
);
export const selectChatPageIsOpen = createSelector(selectFeatureState, (state) => state.isOpen);
