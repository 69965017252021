import { createAction } from '@ngrx/store';
import {
  ActionCreator,
  Creator,
  DisallowArraysAndTypeProperty,
  FunctionWithParametersType,
  TypedAction,
} from '@ngrx/store/src/models';

// ReExport
export { props } from '@ngrx/store';

// Overloads Timestamped createAction Creator
export function createTimestampedAction<T extends string>(
  type: T
): ActionCreator<T, () => { timestamp: number } & TypedAction<T>>;
export function createTimestampedAction<T extends string, P extends object>(
  type: T,
  config: {
    _as: 'props';
    _p: P;
  }
): ActionCreator<T, (props: P) => P & { timestamp: number } & TypedAction<T>>;
export function createTimestampedAction<T extends string, P extends any[], R extends object>(
  type: T,
  creator: Creator<P, DisallowArraysAndTypeProperty<R>>
): FunctionWithParametersType<P, R & { timestamp: number } & TypedAction<T>> & TypedAction<T>;

// Generic Timestamped createAction Creator
export function createTimestampedAction(...config: any[]) {
  const creator = createAction.apply(null, config);
  return Object.defineProperty(
    (...args: any[]) => ({
      timestamp: Date.now(),
      ...creator.apply(null, args),
    }),
    'type',
    {
      value: config[0],
      writable: false,
    }
  );
}

export const createIdAction = (type: string) =>
  createTimestampedAction(type, (id: string) => ({ id }));

export const createErrorAction = (type: string) =>
  createTimestampedAction(
    type,
    (message: string, error: { message: string; error?: string; code?: number }) => ({
      message,
      error: {
        message: !error
          ? 'unknown error'
          : typeof error.error === 'string'
          ? error.error
          : error.message,
        code: (error && error.code) || null,
      },
    })
  );
