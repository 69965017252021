import { createTimestampedAction, createErrorAction, props } from '@app/helpers';
import { GeoCoordinates } from '@app/models';

enum Action {
  POSITION_UPDATED = '[Geolocation] Position Updated',
  POSITION_ERROR = '[Geolocation] Position Error',
}

export const positionUpdated = createTimestampedAction(
  Action.POSITION_UPDATED,
  props<{ position: GeoCoordinates }>()
);

export const positionFailure = createErrorAction(Action.POSITION_ERROR);
