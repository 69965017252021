import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  DetailPopoverComponent,
  InfoPopoverComponent,
  QuestionPopoverComponent,
  ScorePopoverComponent,
} from '@app/components';
import { environment } from '@app/env';
import { Rank } from '@app/models';
import { ApiService } from '@app/services';
import {
  AlertController,
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { ModalOptions, OverlayEventDetail } from '@ionic/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ViewerModalComponent } from 'ngx-ionic-image-viewer';
import { combineLatest, concat, defer, from, merge, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  endWith,
  exhaustMap,
  filter,
  finalize,
  ignoreElements,
  map,
  mergeMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  ChallengeActions,
  DetailPopoverActions,
  GeolocationActions,
  HomePageActions,
  JourneyActions,
  JourneyPageActions,
  LoggingActions,
  ManagePageActions,
  PreviewPopoverActions,
  QuestionPopoverActions,
  RankingApiActions,
  RouteServiceActions,
  TriggerActions,
  WebSocketActions,
} from '../actions';
import * as fromRoot from '../reducers';
import * as fromStore from '../selectors';

@Injectable()
export class UiEffects {
  private modalOptions: ModalOptions | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    private api: ApiService
  ) {}

  addCustomerCss$ = createEffect(
    () =>
      this.store.pipe(
        select(fromStore.selectCustomer),
        filter((customer) => !environment.feature.route && !!customer),
        tap(
          (customer) =>
            ((
              this.document.getElementById('customer-css') as HTMLLinkElement
            ).href = `${customer}.css`)
        )
      ),
    { dispatch: false }
  );

  showError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          HomePageActions.validationError,
          WebSocketActions.socketError,
          RouteServiceActions.loadFailure,
          RouteServiceActions.startFailure,
          GeolocationActions.positionFailure,
          RankingApiActions.loadFailure,
          ManagePageActions.loadListFailure,
          ManagePageActions.updateListFailure,
          ManagePageActions.resetListFailure,
          ChallengeActions.uploadFailure
        ),
        exhaustMap(({ message, error }) =>
          this.alertCtrl
            .create({
              header: 'Fehler',
              subHeader: environment.production
                ? 'Ein Fehler ist aufgetreten'
                : message.replace(/\n/g, '</br>'),
              message: environment.production
                ? message.replace(/\n/g, '</br>')
                : (error && error.message && error.message.replace(/\n/g, '</br>')) ||
                  'Unbekannter Fehler',
              buttons: ['OK'],
            })
            .then((alert) => {
              alert.present();
              return alert.onDidDismiss();
            })
        )
      ),
    { dispatch: false }
  );

  showAbortConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JourneyPageActions.confirmAbort),
      exhaustMap(() =>
        this.alertCtrl
          .create({
            header: 'Route Beenden',
            message: 'Wollen Sie die Route wirklich beenden?',
            buttons: [
              { text: 'Abbrechen', role: 'cancel' },
              { text: 'OK', role: 'abort' },
            ],
          })
          .then((alert) => {
            alert.present();
            return alert.onDidDismiss();
          })
      ),
      filter(({ role }) => role === 'abort'),
      map(() => JourneyActions.flushJourney({ navigateTo: '/manage' }))
    )
  );

  showTimeout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(JourneyActions.journeyTimeout),
        exhaustMap(() =>
          this.alertCtrl
            .create({
              header: 'Zeit Abgelaufen',
              message: 'Das Zeitlimit wurde erreicht. Bitte begeben Sie sich zum Zielort',
              cssClass: 'timeout',
              buttons: ['OK'],
            })
            .then((alert) => {
              alert.present();
              return alert.onDidDismiss();
            })
        )
      ),
    { dispatch: false }
  );

  prepareJourneyCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JourneyActions.allStationsDone),
      mergeMap(() =>
        combineLatest([
          this.store.pipe(select(fromStore.selectUseChallenges)),
          this.store.pipe(select(fromStore.selectTimeout)),
          this.store.pipe(select(fromStore.selectAllTimeTriggersPending)),
        ]).pipe(
          take(1),
          mergeMap(([useChallenges, isTimeout, pending]) =>
            (
              (useChallenges && !isTimeout && !!pending.length
                ? from(
                    this.alertCtrl
                      .create({
                        header: `Noch ${pending.length} ungelöste Challenges`,
                        message: 'Wollen Sie die Challenges noch lösen?',
                        buttons: [
                          { text: 'Nein', role: 'cancel' },
                          { text: 'Ja!', role: 'ok' },
                        ],
                      })
                      .then((alert) => {
                        alert.present();
                        return alert.onDidDismiss();
                      })
                  )
                : of({ role: 'cancel' })) as Observable<{ role: string }>
            ).pipe(
              mergeMap(({ role }) =>
                from(role === 'ok' ? pending : []).pipe(
                  concatMap(({ id }) =>
                    concat(
                      of(TriggerActions.showChallenge(id)),
                      this.actions$.pipe(
                        ofType(QuestionPopoverActions.endChallenge),
                        take(1),
                        ignoreElements()
                      )
                    )
                  ),
                  endWith(JourneyActions.journeyComplete())
                )
              )
            )
          )
        )
      )
    )
  );

  showComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JourneyActions.journeyComplete),
      exhaustMap(() =>
        this.alertCtrl
          .create({
            header: 'Route Abgeschlossen',
            message: 'Sie haben die Route erfolgreich abgeschlossen!',
            buttons: ['OK'],
          })
          .then((alert) => {
            alert.present();
            return alert.onDidDismiss();
          })
      ),
      map(() => JourneyPageActions.showScore())
    )
  );

  closeAllOnTimeout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(JourneyActions.journeyTimeout, JourneyActions.allStationsDone),
        mergeMap(() => this.modalCtrl.getTop()),
        tap((modal) => {
          if (modal) {
            console.log('tapDismissModal');
            modal.dismiss();
          }
        })
      );
    },
    { dispatch: false }
  );

  showPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JourneyPageActions.showPreview),
      mergeMap(({ id, isOverview }) =>
        this.showModal({
          component: InfoPopoverComponent,
          componentProps: { station: id, isOverview },
          backdropDismiss: true,
          animated: true,
          cssClass: 'overview',
        })
      ),
      map(({ data, role }) =>
        role === 'show-detail'
          ? PreviewPopoverActions.showDetail(data.station)
          : PreviewPopoverActions.hidePreview()
      )
    )
  );

  showWelcome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JourneyPageActions.showWelcome),
      mergeMap(() =>
        this.showModal({
          component: DetailPopoverComponent,
          backdropDismiss: false,
          animated: true,
          cssClass: 'welcome',
        })
      ),
      map(() => DetailPopoverActions.hideWelcome(null))
    )
  );

  showDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PreviewPopoverActions.showDetail),
      mergeMap(({ id }) =>
        this.showModal({
          component: DetailPopoverComponent,
          componentProps: { id },
          backdropDismiss: false,
          animated: true,
          cssClass: 'station',
        })
      ),
      map(({ data }) => DetailPopoverActions.hideDetail(data.id))
    )
  );

  showChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        JourneyPageActions.showChallenge,
        TriggerActions.showChallenge,
        ChallengeActions.restored
      ),
      mergeMap(({ id }) =>
        this.showModal({
          component: QuestionPopoverComponent,
          componentProps: { id },
          backdropDismiss: false,
          animated: true,
          cssClass: 'challenge',
        })
      ),
      map(({ data }) => QuestionPopoverActions.endChallenge(data.id))
    )
  );

  updateScoreData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RankingApiActions.updateRanking),
      exhaustMap(() => {
        let indicator: HTMLIonLoadingElement;
        return merge(
          this.loadingCtrl
            .create()
            .then((loading) => {
              indicator = loading;
              return loading.present();
            })
            .then(() => JourneyPageActions.sendLogs()),
          this.actions$.pipe(
            ofType(LoggingActions.sendLogSuccess, LoggingActions.sendLogFailure),
            take(1),
            withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectSession)))),
            mergeMap(([_, session]) =>
              this.api.read<Rank[]>(`rankings?journeyid=${session}`).pipe(
                map((ranking) => RankingApiActions.loadSuccess({ ranking })),
                catchError((error) =>
                  of(RankingApiActions.loadFailure('Ranking konnte nicht geladen werden', error))
                ),
                finalize(() => indicator.dismiss())
              )
            )
          )
        );
      })
    )
  );

  showScore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JourneyPageActions.showScore),
      mergeMap(() =>
        this.modalCtrl
          .create({
            component: ScorePopoverComponent,
            backdropDismiss: false,
            animated: true,
            cssClass: 'score',
          })
          .then((modal) => {
            modal.present();
            return modal.onDidDismiss();
          })
      ),
      filter(({ data }) => data && data.done),
      map(() => JourneyActions.flushJourney({ navigateTo: '/manage' }))
    )
  );

  showChallengeMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ChallengeActions.statusUpdated,
          ChallengeActions.challengeSucceeded,
          ChallengeActions.challengeFailed
        ),
        filter(({ message }) => message !== null),
        concatMap(({ type, message }) =>
          this.toastCtrl
            .create({
              message,
              // duration: environment.toastDuration,
              keyboardClose: true,
              buttons: ['OK'],
              position: 'middle',
              cssClass:
                type === ChallengeActions.challengeFailed.type
                  ? 'failed'
                  : type === ChallengeActions.challengeSucceeded.type
                  ? 'success'
                  : 'default',
            })
            .then((toast) => toast.present())
        )
      ),
    { dispatch: false }
  );

  showMessageReceived$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WebSocketActions.messageReceived),
        withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectChatPageIsOpen)))),
        filter(([_, isOpen]) => !isOpen),
        concatMap(() =>
          this.toastCtrl
            .create({
              message: 'Neue Chatnachricht',
              keyboardClose: true,
              buttons: ['OK'],
              position: 'middle',
              cssClass: 'default',
            })
            .then((toast) => toast.present())
        )
      ),
    { dispatch: false }
  );

  showChallengeTriggered$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TriggerActions.locationTriggered, TriggerActions.timeTriggered),
        concatMap(() =>
          this.toastCtrl
            .create({
              message: 'Neue Challenge verfügbar!!!',
              keyboardClose: true,
              buttons: ['OK'],
              position: 'middle',
              cssClass: 'failed',
            })
            .then((toast) => toast.present())
        )
      ),
    { dispatch: false }
  );

  private showModal(options: ModalOptions): Promise<OverlayEventDetail<any>> {
    this.modalOptions = options;
    return this.modalCtrl
      .create(options)
      .then((modal) => modal.present().then(() => modal.onDidDismiss()))
      .then(({ data, role }) =>
        role === 'image-viewer' ? this.showImageViewer(data.src) : { data, role }
      );
  }

  private showImageViewer(src: string) {
    return this.modalCtrl
      .create({
        component: ViewerModalComponent,
        componentProps: {
          src,
          scheme: 'dark',
        },
        cssClass: 'ion-img-viewer',
        keyboardClose: true,
        showBackdrop: true,
      })
      .then((viewer) => {
        viewer.present();
        return viewer.onDidDismiss();
      })
      .then(() => this.showModal(this.modalOptions));
  }
}
