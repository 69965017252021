import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CriteriaDisplay, QuestionChallengeEntity } from '@app/models';
import { flip, includes, map, pipe, replace, toLower } from 'ramda';
import { ChallengeComponent } from '../challenge-component.interface';
import { ToastController } from '@ionic/angular';
import { environment } from '@app/env';

const sanitizeAnswer = pipe(toLower, replace(/\s/g, ''));
const included = flip(includes) as unknown as (xs: string[]) => (x: string) => boolean;
const createCheck = (list: string[]) => pipe(sanitizeAnswer, included(map(sanitizeAnswer, list)));

@Component({
  selector: 'app-question-challenge',
  templateUrl: './question-challenge.component.html',
  styleUrls: ['./question-challenge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionChallengeComponent implements OnInit, ChallengeComponent {
  @Input() answers: QuestionChallengeEntity['answers'] = [];
  @Input() status: CriteriaDisplay;
  @Output() challengeAttempt = new EventEmitter<boolean>();

  public answer = '';
  private checkAnswer: (answer: string) => boolean;

  constructor(private toastCtrl: ToastController) {}

  ngOnInit() {
    this.checkAnswer = createCheck(this.answers);
  }

  solve() {
    if (this.answer.length !== 0) {
      if (this.checkAnswer(this.answer)) {
        this.challengeAttempt.emit(true);
      } else {
        this.challengeAttempt.emit(false);
      }
      this.answer = '';
    } else {
      this.toastCtrl
        .create({
          message: 'Bitte geben Sie eine Antwort ein.',
          duration: environment.toastDuration,
          keyboardClose: true,
          position: 'middle',
          // cssClass: 'warning',
        })
        .then((toast) => toast.present());
    }
  }
}
