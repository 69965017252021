import { ChallengeEntity, ChallengeStatus, CriteriaStatus } from '@app/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  JourneyPageActions,
  RouteServiceActions,
  TriggerActions,
  QuestionPopoverActions,
  ChallengeActions,
  JourneyActions,
} from '../actions';

export const featureKey = 'challenges';

export const featureAdapter: EntityAdapter<ChallengeEntity> =
  createEntityAdapter<ChallengeEntity>();

export interface State extends EntityState<ChallengeEntity> {
  selectedChallenge: string;
  status: CriteriaStatus;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedChallenge: null,
  status: null,
});

export const reducer = createReducer(
  initialState,
  on(
    RouteServiceActions.loadSuccess,
    (state, { challenges }): State =>
      featureAdapter.addMany(challenges, { ...state, selectedChallenge: null })
  ),
  on(
    TriggerActions.locationTriggered,
    TriggerActions.stationTriggered,
    TriggerActions.timeTriggered,
    (state, { id }): State =>
      featureAdapter.updateOne({ id, changes: { status: ChallengeStatus.TRIGGERED } }, state)
  ),
  on(
    JourneyPageActions.showChallenge,
    TriggerActions.showChallenge,
    (state, { id }): State => ({ ...state, selectedChallenge: id })
  ),
  on(
    QuestionPopoverActions.startChallenge,
    (state, { id, timestamp }): State =>
      featureAdapter.updateOne({ id, changes: { timestamp } }, state)
  ),
  on(
    QuestionPopoverActions.endChallenge,
    (state): State => ({ ...state, status: null, selectedChallenge: null })
  ),
  on(ChallengeActions.statusUpdated, (state, { status }): State => ({ ...state, status })),
  on(
    ChallengeActions.challengeFailed,
    (state): State =>
      featureAdapter.updateOne(
        {
          id: state.selectedChallenge,
          changes: { status: ChallengeStatus.FAILURE },
        },
        state
      )
  ),
  on(
    ChallengeActions.challengeSucceeded,
    (state): State =>
      featureAdapter.updateOne(
        {
          id: state.selectedChallenge,
          changes: { status: ChallengeStatus.SUCCESS },
        },
        state
      )
  ),
  on(JourneyActions.clearJourney, () => initialState)
);

const { selectEntities, selectAll } = featureAdapter.getSelectors();

const selectFeatureState = createFeatureSelector<State>(featureKey);
const selectChallengeEntities = createSelector(selectFeatureState, selectEntities);
const selectAllChallenges = createSelector(selectFeatureState, selectAll);
const selectCurrentChallengeId = createSelector(
  selectFeatureState,
  (state) => state.selectedChallenge
);

export const selectChallengeEntitiesDone = createSelector(selectAllChallenges, (challenges) =>
  challenges.reduce(
    (result, challenge) =>
      challenge.status === ChallengeStatus.FAILURE || challenge.status === ChallengeStatus.SUCCESS
        ? { ...result, [challenge.id]: challenge }
        : result,
    {} as Dictionary<ChallengeEntity>
  )
);

export const selectCriteriaStatus = createSelector(selectFeatureState, (state) => state.status);

export const selectCriteriaDisplay = createSelector(
  selectCriteriaStatus,
  (state) => (state && state.display) || null
);

export const selectCurrentChallenge = createSelector(
  selectChallengeEntities,
  selectCurrentChallengeId,
  (challengeEntities, challengeId) => (challengeId && challengeEntities[challengeId]) || null
);

export const selectCurrentCriteria = createSelector(
  selectCurrentChallenge,
  (challenge) => challenge && challenge.criteria
);
