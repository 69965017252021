import { Injectable } from '@angular/core';
import { RouteService } from '@app/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { defer, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ManagePageActions, RouteServiceActions, RoutesPageActions } from '../actions';
import * as fromRoot from '../reducers';
import * as fromStore from '../selectors';

@Injectable()
export class RouteEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private routeService: RouteService
  ) {}

  loadRouteData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManagePageActions.routeSelected, RoutesPageActions.routeSelected),
      mergeMap(({ options }) =>
        this.routeService.loadRouteData(options.route).pipe(
          map((routeData) => RouteServiceActions.loadSuccess(routeData)),
          catchError((error) =>
            of(RouteServiceActions.loadFailure('Route konnte nicht geladen werden', error))
          )
        )
      )
    )
  );

  startRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManagePageActions.teamSelected),
      withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectOptions)))),
      mergeMap(([_, { route, team, group }]) =>
        this.routeService.startRoute({ route, team, group }).pipe(
          map((response) => RouteServiceActions.startSuccess({ response })),
          catchError((error) =>
            of(RouteServiceActions.startFailure('Fehler beim Starten der Route', error))
          )
        )
      )
    )
  );
}
