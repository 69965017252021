import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Content } from '@app/models';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentComponent {
  @Input() content: Content;
  @Input() zoomEnabled = true;

  constructor(private modalCtrl: ModalController) {}

  openViewer(src: string) {
    this.modalCtrl.dismiss({ src }, 'image-viewer');
  }
}
