import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { SingleChoiceChallengeEntity, CriteriaDisplay } from '@app/models';
import { ChallengeComponent } from '../challenge-component.interface';

@Component({
  selector: 'app-singlechoice-challenge',
  templateUrl: './singlechoice-challenge.component.html',
  styleUrls: ['./singlechoice-challenge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SinglechoiceChallengeComponent implements OnInit, ChallengeComponent {
  @Input() options: SingleChoiceChallengeEntity['options'] = [];
  @Input() status: CriteriaDisplay;
  @Output() challengeAttempt = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  solve(index: number) {
    if (this.options[index].solution) {
      this.challengeAttempt.emit(true);
    } else {
      this.challengeAttempt.emit(false);
    }
  }
}
