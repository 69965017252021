import { StorageSyncEffects } from 'ngrx-store-ionic-storage';
import { ChatEffects } from './chat.effects';
import { RouterEffects } from './router.effects';
import { RouteEffects } from './route.effects';
import { JourneyEffects } from './journey.effects';
import { TriggerEffects } from './trigger.effects';
import { ChallengeEffects } from './challenge.effects';
import { UiEffects } from './ui.effects';
import { LoggingEffects } from './logging.effects';
import { SoundEffects } from './sound.effects';

export const StoreEffects = [
  // TODO: Readd Storage Sync Effects
  StorageSyncEffects,
  LoggingEffects,
  ChatEffects,
  ChallengeEffects,
  RouteEffects,
  JourneyEffects,
  TriggerEffects,
  RouterEffects,
  UiEffects,
  SoundEffects,
];
