import { createTimestampedAction, props } from '@app/helpers';
import { OutgoingChatMessage } from '@app/models';
import * as moment from 'moment';

enum Action {
  CHAT_OPENED = '[Chat Page] Chat Opened',
  CHAT_CLOSED = '[Chat Page] Chat Closed',
  USER_TYPING = '[Chat Page] User Typing',
  SEND_MESSAGE = '[Chat Page] Send Message',
}

export const chatOpened = createTimestampedAction(Action.CHAT_OPENED);
export const chatClosed = createTimestampedAction(Action.CHAT_CLOSED);
export const userTyping = createTimestampedAction(
  Action.USER_TYPING,
  props<{ userTyping: boolean }>()
);

export const sendMessage = createTimestampedAction(Action.SEND_MESSAGE, (content: string): {
  message: OutgoingChatMessage;
} => ({
  message: {
    timestamp: Date.now(),
    content,
    type: 'outgoing',
    time: moment(Date.now()).format('HH:mm'),
  },
}));
