import { createTimestampedAction, createIdAction } from '@app/helpers';

enum Action {
  NEXT_PAGE = '[Detail Popover] Show Next Page',
  PREV_PAGE = '[Detail Popover] Show Prev Page',
  HIDE_DETAIL = '[Detail Popover] Hide Detail',
  HIDE_WELCOME = '[Detail Popover] Hide Welcome',
}

export const hideDetail = createIdAction(Action.HIDE_DETAIL);
export const hideWelcome = createIdAction(Action.HIDE_DETAIL);
export const nextPage = createTimestampedAction(Action.NEXT_PAGE);
export const prevPage = createTimestampedAction(Action.PREV_PAGE);
