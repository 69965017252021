import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Content, Layout, PageEntity } from '@app/models';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent implements OnChanges {
  @Input() page: PageEntity;

  public head: Content = null;
  public contents: Content[] = [];
  public layout: Layout = null;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.page.currentValue) {
      if (this.page.layout) {
        this.layout = this.page.layout;
        switch (this.page.layout.type) {
          case 'split':
            const [head, ...contents] = this.page.contents || [null];
            this.head = head;
            this.contents = contents;
            break;
          default:
            this.head = null;
            this.contents = this.page.contents || [];
            break;
        }
      } else {
        this.head = null;
        this.contents = [];
        this.layout = null;
      }
    }
  }
}
