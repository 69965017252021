import { EnvironmentConfig } from '@app/models';

export const environment: EnvironmentConfig = {
  production: false,
  native: false,
  toastDuration: 2000,
  apiHost: 'https://4sar185na0.execute-api.eu-central-1.amazonaws.com/prod/',
  routesBase: 'routes-preview',
  triggerDistance: 20,
  emulate: {
    media: false,
    approach: true,
    files: false,
    api: false,
    data: false,
    device: '4c943905ce51d355', // Marburg
  },
  feature: {
    preview: false,
    offline: false,
    chat: true,
    reset: false,
    route: '1a028333-65d2-4858-b417-c1fdc244a95f',
    // route: '1a028333-65d2-4858-b417-c1fdc244a95f',
  },
  socket: {
    url: 'wss://ec2-001.aws.freesixtyfive.de',
    path: 'erlebnisapp/socket.io',
    port: 443,
  },
  map: {
    provider: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    fileProvider: '{z}-{x}-{y}.png',
    maxZoom: 17,
    minZoom: 12,
    useTms: false,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error'; // Included with Angular CLI.
