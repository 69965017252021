import { createTimestampedAction, createIdAction, props } from '@app/helpers';

enum Action {
  TIMEOUT = '[Journey] Time Is Up',
  STATIONS_DONE = '[Journey] Stations Done',
  COMPLETE = '[Journey] Journey Complete',
  NEXT_TARGET = '[Journey] Target Next Station',
  FLUSH_JOURNEY = '[Journey] Flush Journey Logs',
  CLEAR_JOURNEY = '[Journey] Clear Journey Data',
}

export const journeyTimeout = createTimestampedAction(Action.TIMEOUT);
export const journeyComplete = createTimestampedAction(Action.COMPLETE);

export const allStationsDone = createTimestampedAction(Action.STATIONS_DONE);
export const targetNextStation = createIdAction(Action.NEXT_TARGET);

export const flushJourney = createTimestampedAction(
  Action.FLUSH_JOURNEY,
  props<{ navigateTo: string }>()
);
export const clearJourney = createTimestampedAction(Action.CLEAR_JOURNEY);
