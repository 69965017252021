import { createTimestampedAction, createIdAction, props } from '@app/helpers';

enum Action {
  NEXT_PAGE = '[Question Popover] Show Next Page',
  PREV_PAGE = '[Question Popover] Show Prev Page',
  CHALLENGE_ATTEMPTED = '[Question Popover] Challenge Attempted',
  START_CHALLENGE = '[Question Popover] Start Challenge',
  END_CHALLENGE = '[Question Popover] End Challenge',
}

export const startChallenge = createIdAction(Action.START_CHALLENGE);
export const endChallenge = createIdAction(Action.END_CHALLENGE);

export const nextPage = createTimestampedAction(Action.NEXT_PAGE);
export const prevPage = createTimestampedAction(Action.PREV_PAGE);

export const challengeAttempted = createTimestampedAction(
  Action.CHALLENGE_ATTEMPTED,
  props<{ id: string; successful: boolean }>()
);
