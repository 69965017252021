// Angular Dependencies
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

// Third Party Libs
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { SocketIoModule } from 'ngx-socket-io';

// Ionic Native Plugins
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Zip } from '@ionic-native/zip/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';

// Project Components and Modules
import { environment } from '@app/env';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './interceptors';
import { RootStoreModule } from './root-store/root-store.module';
import { FileService, WebFileService } from './services';
import { LogErrorHandler } from './class/log-error.handler';

const fileServiceFactory = (
  platform: Platform,
  http: HttpClient,
  transfer: FileTransfer,
  file: File,
  zip: Zip,
  webview: WebView,
  network: Network
) => {
  return !environment.native || (environment.emulate && environment.emulate.files)
    ? new WebFileService(http)
    : new FileService(transfer, file, zip, webview, platform, http, network);
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({ mode: 'md' }),
    SocketIoModule.forRoot({
      url: `${environment.socket.url}:${environment.socket.port}`,
      options: {
        transports: ['websocket'],
        path: `/${environment.socket.path || 'socket.io'}`,
        autoConnect: false,
      },
    }),
    RootStoreModule,
    AppRoutingModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    ScreenOrientation,
    Network,
    CameraPreview,
    File,
    FileTransfer,
    Geolocation,
    Zip,
    WebView,
    httpInterceptorProviders,
    {
      provide: FileService,
      useFactory: fileServiceFactory,
      deps: [Platform, HttpClient, FileTransfer, File, Zip, WebView, Network],
    },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: LogErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
