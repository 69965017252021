import { Criteria } from './criteria.model';
import { Page } from './page.model';
import { Trigger } from './trigger.model';

export type ChallengeTypes = 'question' | 'singlechoice' | 'createmedia';
export type MediaTypes = 'video' | 'image';

export interface ChallengeType<Type extends ChallengeTypes> {
  id: string;
  type: Type;
  trigger: Trigger;
  criteria: Criteria;
  /**
   * @minItems 1
   */
  pages: Page[];
  /**
   * @minItems 1
   */
  success: Page[];
  /**
   * @minItems 1
   */
  failure: Page[];
}

export interface QuestionChallenge extends ChallengeType<'question'> {
  /**
   * @minItems 1
   */
  answers: string[];
}

export interface SingleChoiceChallenge extends ChallengeType<'singlechoice'> {
  /**
   * @minItems 2
   */
  options: { text: string; solution: boolean }[];
}

export interface CreateMediaChallenge extends ChallengeType<'createmedia'> {
  /**
   * @default "image"
   */
  mediaType: MediaTypes;
}

export type Challenge = QuestionChallenge | SingleChoiceChallenge | CreateMediaChallenge;

export enum ChallengeStatus {
  OPEN = 'OPEN',
  TRIGGERED = 'TRIGGERED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

interface ChallengeData {
  timestamp: number;
  status: ChallengeStatus;
}

export type QuestionChallengeEntity = Omit<
  QuestionChallenge,
  'pages' | 'success' | 'failure' | 'trigger'
> &
  ChallengeData;

export type SingleChoiceChallengeEntity = Omit<
  SingleChoiceChallenge,
  'pages' | 'success' | 'failure' | 'trigger'
> &
  ChallengeData;

export type CreateMediaChallengeEntity = Omit<
  CreateMediaChallenge,
  'pages' | 'success' | 'failure' | 'trigger'
> &
  ChallengeData;

export type ChallengeEntity =
  | QuestionChallengeEntity
  | SingleChoiceChallengeEntity
  | CreateMediaChallengeEntity;
