import { ensureResult } from '@app/helpers';
import {
  ChallengeStatus,
  distanceOf,
  MarkerIcons,
  StationMarker,
  StationStatus,
  LogParams,
} from '@app/models';
import { createSelector } from '@ngrx/store';
import * as fromChallenges from './reducers/challenges.reducer';
import * as fromChat from './reducers/chat.reducer';
import * as fromJourney from './reducers/journey.reducer';
import * as fromPages from './reducers/pages.reducer';
import * as fromRoute from './reducers/route.reducer';
import * as fromStations from './reducers/stations.reducer';
import * as fromTriggers from './reducers/triggers.reducer';
import * as fromLogging from './reducers/logging.reducer';

// Utility Selectorys
const selectDistance = createSelector(
  fromJourney.selectPosition,
  fromStations.selectTargetStation,
  (position, station) => distanceOf(position, station && station.geoposition)
);

const selectChallengesActive = createSelector(
  fromRoute.selectUseChallenges,
  fromJourney.selectTimeout,
  fromJourney.selectHasEnded,
  (challenges, timeout, hasEnded) => !hasEnded && !timeout && challenges
);

const selectStationChallengesStatus = createSelector(
  fromTriggers.selectStationTriggersDone,
  fromChallenges.selectChallengeEntitiesDone,
  (triggers, challenges) =>
    triggers.reduce(
      (result, trigger) =>
        challenges[trigger.id]
          ? { ...result, [trigger.station]: challenges[trigger.id].status }
          : result,
      {} as Dictionary<ChallengeStatus>
    )
);

// Public fromStore Selectors
export const { selectChatMessages, selectChatPageIsOpen } = fromChat;
export const { selectMode, selectOptions, selectSounds, selectUseChallenges } = fromRoute;
export const { selectHasStations } = fromStations;
export const { selectCriteriaStatus, selectCurrentChallenge, selectCurrentCriteria } =
  fromChallenges;
export const {
  selectSession,
  selectVoucherCode,
  selectCustomer,
  selectHasStarted,
  selectHasEnded,
  selectStartedAt,
  selectIsRunning,
  selectPosition,
  selectRanking,
  selectTimeout,
} = fromJourney;
export const selectAllTimeTriggersPending = fromTriggers.selectTimedTriggersPending;

export const selectLog = createSelector(
  fromJourney.selectSession,
  fromLogging.selectLog,
  (session, { challenges, positions }): LogParams => ({ session, challenges, positions })
);

export const selectLogConfig = createSelector(
  fromJourney.selectIsRunning,
  fromLogging.selectLogSend,
  fromLogging.selectLogWrite,
  (isRunning, send, write) => ({ isRunning, send, write })
);

export const selectTimeTriggersPending = createSelector(
  selectChallengesActive,
  fromTriggers.selectTimedTriggersPending,
  (active, triggers) => ensureResult(triggers, active)
);

export const selectLocationTriggersPending = createSelector(
  selectChallengesActive,
  fromTriggers.selectLocationTriggersPending,
  (active, triggers) => ensureResult(triggers, active)
);

export const selectStationTriggersPending = createSelector(
  selectChallengesActive,
  fromTriggers.selectStationTriggersPending,
  (active, triggers) => ensureResult(triggers, active)
);

export const selectStationTriggerActive = createSelector(
  selectChallengesActive,
  fromTriggers.selectStationsTriggersActive,
  (active, [trigger]) => (active && !!trigger ? trigger : null)
);

export const selectJourneyTime = createSelector(
  fromJourney.selectStartedAt,
  fromJourney.selectIsRunning,
  fromRoute.selectUseTimelimit,
  (startedAt, isRunning, timelimit) => ({
    startedAt,
    isRunning,
    timelimit,
  })
);

export const selectScore = createSelector(
  selectDistance,
  fromRoute.selectUseChallenges,
  fromRoute.selectUseTimelimit,
  fromJourney.selectPoints,
  fromJourney.selectStartedAt,
  fromRoute.selectTitleInternal,
  (distance, challenges, timelimit, points, startedAt, title) => ({
    title,
    ...distance,
    startedAt,
    timelimit,
    points: challenges ? points : null,
  })
);

// export const selectNextTarget = createSelector(
//   fromJourney.selectHasEnded,
//   fromRoute.selectUseLinear,
//   fromStations.selectLastStationId,
//   fromStations.selectNextStationId,
//   (hasEnded, linear, lastStationId, nextStationId) =>
//     hasEnded ? lastStationId : linear ? nextStationId : null
// );

export const selectNextTarget = createSelector(
  fromJourney.selectHasEnded,
  fromRoute.selectUseLinear,
  fromStations.selectNextStationId,
  (hasEnded, linear, nextStationId) => (hasEnded || !linear ? null : nextStationId)
);

const getIcon = (
  marker: MarkerIcons,
  isTarget: boolean,
  isNear: boolean,
  isOverview: boolean,
  stationStatus: StationStatus,
  challengeStatus: ChallengeStatus = null
) => {
  if (!isOverview) {
    if (challengeStatus === ChallengeStatus.SUCCESS) {
      return marker.success;
    } else if (challengeStatus === ChallengeStatus.FAILURE) {
      return marker.failure;
    } else if (stationStatus === StationStatus.OPEN) {
      if (isNear) {
        return marker.near;
      } else if (isTarget) {
        return marker.target;
      }
    }
  }
  return marker.icon;
};

export const selectMarkers = createSelector(
  fromJourney.selectPosition,
  fromJourney.selectHasEnded,
  fromRoute.selectUseLinear,
  fromStations.selectTargetStationId,
  fromStations.selectAllStations,
  selectStationChallengesStatus,
  (
    userPosition,
    hasEnded,
    linear,
    target,
    stations,
    challengesStatus,
    props = { isOverview: false }
  ): StationMarker[] => [
    {
      id: null,
      title: 'Mein Standort',
      geoposition: userPosition,
      visible: !props.isOverview,
      icon: './assets/defaults/position.png',
    },
    ...stations.map(({ id, title, marker, geoposition, status }) => ({
      id,
      title,
      geoposition,
      visible: (!linear && !hasEnded) || props.isOverview || target === id,
      icon: getIcon(
        marker,
        id === target,
        distanceOf(geoposition, userPosition).isNear,
        props.isOverview,
        status,
        challengesStatus[id]
      ),
      visited: status === StationStatus.VISITED,
    })),
  ]
);

export const selectJourneyPage = createSelector(
  fromJourney.selectHasStarted,
  selectChallengesActive,
  fromTriggers.selectOptionalTriggersActive,
  fromRoute.selectStartText,
  fromRoute.selectUseLinear,
  fromRoute.selectUseChallenges,
  fromChat.selectChatNewMessageCount,
  (hasStarted, challengesActive, triggers, startText, isLinear, useChallenges, messageCount) => ({
    startText,
    activeTriggers: ensureResult(triggers, challengesActive),
    messageCount,
    hasStarted,
    hasChallenges: challengesActive && useChallenges,
    isLinear,
  })
);

export const selectPreviewPage = createSelector(
  fromStations.selectTargetStation,
  fromStations.selectLastStationId,
  selectDistance,
  fromJourney.selectHasStarted,
  fromRoute.selectMode,
  (station, lastStationId, distance, hasStarted, { linear }) =>
    station
      ? {
          ...station.preview,
          ...distance,
          hasStarted,
          isLast: !linear && lastStationId === station.id,
        }
      : null
);

export const selectDetailPage = createSelector(
  fromPages.selectCurrentPage,
  fromPages.selectPagination,
  fromStations.selectCurrentStationId,
  (page, pagination, station) => ({ page, pagination, station })
);

export const selectChallengePage = createSelector(
  fromPages.selectCurrentPage,
  fromPages.selectPagination,
  fromChallenges.selectCurrentChallenge,
  fromChallenges.selectCriteriaDisplay,
  (page, pagination, challenge, status) => ({ page, pagination, status, challenge })
);

export const selectChallengeData = createSelector(
  fromChallenges.selectCurrentChallenge,
  fromChallenges.selectCriteriaStatus,
  (challenge, status) => ({ challenge, status })
);
