import { createTimestampedAction, props, createErrorAction } from '@app/helpers';
import { ChatMessageData, IncomingChatMessage } from '@app/models';
import * as moment from 'moment';

enum Action {
  CONNECT_SOCKET = '[WebSocket] Connect Socket',
  DISCONNECT_SOCKET = '[WebSocket] Disconnect Socket',
  SOCKET_VERIFIED = '[WebSocket] Socket Verified',
  SOCKET_ERROR = '[WebSocket] Socket Error',
  SOCKET_CONNECTED = '[WebSocket] Socket Connected',
  SOCKET_DISCONNECTED = '[WebSocket] Socket Disonnected',
  MESSAGE_RECEIVED = '[WebSocket] Message Received',
  OPERATOR_TYPING = '[WebSocket] Operator Typing',
  NETWORK_GONE = '[WebSocket] Network Gone',
  NETWORK_BACK = '[WebSocket] Network Back',
}

export const networkGone = createErrorAction(Action.NETWORK_GONE);
export const networkBack = createTimestampedAction(
  Action.NETWORK_BACK,
  props<{ session: string }>()
);

export const connectSocket = createTimestampedAction(
  Action.CONNECT_SOCKET,
  props<{ session: string }>()
);
export const disconnectSocket = createTimestampedAction(Action.DISCONNECT_SOCKET);
export const socketError = createErrorAction(Action.SOCKET_ERROR);

export const socketConnected = createTimestampedAction(Action.SOCKET_CONNECTED);
export const socketDisconnected = createTimestampedAction(
  Action.SOCKET_DISCONNECTED,
  props<{ message: string }>()
);
export const socketVerified = createTimestampedAction(
  Action.SOCKET_VERIFIED,
  props<{ session: string; isConnected: boolean }>()
);

export const operatorTyping = createTimestampedAction(
  Action.OPERATOR_TYPING,
  props<{ typing: boolean }>()
);

export const messageReceived = createTimestampedAction(
  Action.MESSAGE_RECEIVED,
  (
    data: ChatMessageData
  ): {
    message: IncomingChatMessage;
  } => ({
    message: {
      ...data,
      type: 'incoming',
      time: moment(data.timestamp).format('HH:mm'),
    },
  })
);
