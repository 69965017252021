import { Injectable } from '@angular/core';
import { environment } from '@app/env';
import { MessageDataTypes, SocketMessage } from '@app/models';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { StorageSyncActions } from 'ngrx-store-ionic-storage';
import { Socket } from 'ngx-socket-io';
import { defer } from 'rxjs';
import { filter, map, mergeMapTo, take, tap, withLatestFrom } from 'rxjs/operators';
import { ChatPageActions, JourneyActions, RouteServiceActions, WebSocketActions } from '../actions';
import * as fromRoot from '../reducers';
import * as fromStore from '../selectors';

@Injectable()
export class ChatEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private socket: Socket,
    private network: Network
  ) {
    if (environment.feature.chat) {
      this.socket.on('connect', () => this.store.dispatch(WebSocketActions.socketConnected()));
      this.socket.on('disconnect', (message: string) =>
        this.store.dispatch(WebSocketActions.socketDisconnected({ message }))
      );
      this.socket.on('error', (error: Error) =>
        this.store.dispatch(WebSocketActions.socketError('Websocket Fehler', error))
      );
      this.socket.on('connect_error', (error: Error) => {
        if (this.network.type === this.network.Connection.NONE) {
          this.network
            .onConnect()
            .pipe(
              take(1),
              mergeMapTo(defer(() => this.store.pipe(select(fromStore.selectSession))))
            )
            .subscribe((session) => this.store.dispatch(WebSocketActions.networkBack({ session })));

          this.store.dispatch(WebSocketActions.networkGone('Websocket Verbindungsfehler', error));
        }
      });
      this.socket.on('message', ({ data }: SocketMessage<MessageDataTypes>) =>
        this.store.dispatch(WebSocketActions.messageReceived(data))
      );
      this.socket.on('startTyping', () =>
        this.store.dispatch(WebSocketActions.operatorTyping({ typing: true }))
      );
      this.socket.on('stopTyping', () =>
        this.store.dispatch(WebSocketActions.operatorTyping({ typing: false }))
      );
    }
  }

  setConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        StorageSyncActions.HYDRATED,
        RouteServiceActions.startSuccess,
        JourneyActions.clearJourney
      ),
      filter(() => environment.feature.chat),
      mergeMapTo(defer(() => this.store.pipe(select(fromStore.selectSession)))),
      map((session) => {
        if (!this.socket.ioSocket.connected && typeof session === 'string' && !!session.length) {
          return WebSocketActions.connectSocket({ session });
        } else if (
          this.socket.ioSocket.connected &&
          (session === null || typeof session !== 'string' || !session.length)
        ) {
          return WebSocketActions.disconnectSocket();
        } else {
          return WebSocketActions.socketVerified({
            session,
            isConnected: this.socket.ioSocket.connected,
          });
        }
      })
    )
  );

  connectSocket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WebSocketActions.connectSocket, WebSocketActions.networkBack),
        tap(({ session }) => {
          this.socket.ioSocket.io.opts.query = { session };
          this.socket.connect();
        })
      ),
    {
      dispatch: false,
    }
  );

  disconnectSocket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WebSocketActions.disconnectSocket, WebSocketActions.networkGone),
        tap(() => this.socket.disconnect())
      ),
    { dispatch: false }
  );

  sendMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatPageActions.sendMessage),
        withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectSession)))),
        tap(
          ([
            {
              message: { content, timestamp },
            },
            session,
          ]) => this.socket.emit('message', { session, data: { content, timestamp } })
        )
      ),
    {
      dispatch: false,
    }
  );

  emitUserTyping$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatPageActions.userTyping),
        withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectSession)))),
        tap(([{ userTyping }, session]) =>
          this.socket.emit(userTyping ? 'startTyping' : 'stopTyping', {
            session,
          })
        )
      ),
    {
      dispatch: false,
    }
  );
}
