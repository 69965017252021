import { StationEntity, StationStatus } from '@app/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { find, head, last, propOr } from 'ramda';
import {
  DetailPopoverActions,
  JourneyActions,
  JourneyPageActions,
  ManagePageActions,
  PreviewPopoverActions,
  RouteServiceActions,
} from '../actions';

export const featureKey = 'stations';

export const featureAdapter: EntityAdapter<StationEntity> = createEntityAdapter<StationEntity>({
  sortComparer: false,
});

export interface State extends EntityState<StationEntity> {
  selectedStation: string;
  targetedStation: string;
}

export const initialState: State = featureAdapter.getInitialState({
  selectedStation: null,
  targetedStation: null,
});

const propIdOrNull: <T extends Record<'id', string>>(obj: T) => string | null = propOr(null, 'id');

const { selectEntities, selectAll, selectIds } = featureAdapter.getSelectors();
const getFirstStationId = (state: State) => head(selectIds(state) as unknown as string);
const getLastStationId = (state: State) => last(selectIds(state) as unknown as string);
const getNextStationId = (state: State) =>
  propIdOrNull(find((station) => station.status === StationStatus.OPEN, selectAll(state)));

export const reducer = createReducer(
  initialState,
  on(
    RouteServiceActions.loadSuccess,
    (state, { stations }): State => featureAdapter.addMany(stations, state)
  ),
  on(ManagePageActions.modeSelected, (state, { mode: { linear } }) => ({
    ...state,
    targetedStation: linear ? getFirstStationId(state) : null,
  })),
  on(
    JourneyPageActions.showPreview,
    PreviewPopoverActions.hidePreview,
    (state, action): State => ({
      ...state,
      selectedStation: 'id' in action ? action.id : null,
    })
  ),
  on(
    JourneyActions.targetNextStation,
    (state, { id }): State => ({ ...state, targetedStation: id })
  ),
  on(JourneyActions.journeyTimeout, (state): State => {
    const lastId = getLastStationId(state);
    return featureAdapter.map(
      (station) =>
        station.id === lastId
          ? { ...station, status: StationStatus.OPEN }
          : station.status === StationStatus.OPEN
          ? { ...station, status: StationStatus.VISITED }
          : station,
      {
        ...state,
        targetedStation: lastId,
      }
    );
  }),
  on(
    DetailPopoverActions.hideDetail,
    (state, { id }): State =>
      id === getLastStationId(state)
        ? featureAdapter.map((station) => ({ ...station, status: StationStatus.VISITED }), {
            ...state,
            selectedStation: null,
          })
        : featureAdapter.updateOne(
            { id, changes: { status: StationStatus.VISITED } },
            { ...state, selectedStation: null }
          )
  ),
  on(JourneyActions.clearJourney, () => initialState)
);

const selectFeatureState = createFeatureSelector<State>(featureKey);
const selectStationEntities = createSelector(selectFeatureState, selectEntities);

export const selectAllStations = createSelector(selectFeatureState, selectAll);
export const selectCurrentStationId = createSelector(
  selectFeatureState,
  (state) => state.selectedStation
);
export const selectTargetStationId = createSelector(
  selectFeatureState,
  (state) => state.targetedStation
);

export const selectTargetStation = createSelector(
  selectStationEntities,
  selectCurrentStationId,
  selectTargetStationId,
  (stationEntities, selectedId, targetedId) =>
    stationEntities[selectedId ? selectedId : targetedId] || null
);

export const selectLastStationId = createSelector(selectFeatureState, getLastStationId);
export const selectNextStationId = createSelector(selectFeatureState, getNextStationId);
export const selectHasStations = createSelector(
  selectAllStations,
  (stations) => !!stations.filter((station) => station.status === StationStatus.OPEN).length
);
