import { Injectable } from '@angular/core';
import { formatDuration, log } from '@app/helpers';
import {
  AttemptsCriteria,
  AttemptsCriteriaStatus,
  TimelimitCriteria,
  TimelimitCriteriaStatus,
  CriteriaStatus,
} from '@app/models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { defer, from, interval, merge, of, timer, combineLatest, forkJoin, Observable } from 'rxjs';
import {
  map,
  mapTo,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom,
  tap,
  filter,
  mergeMap,
  catchError,
  finalize,
} from 'rxjs/operators';
import { StorageSyncActions } from 'ngrx-store-ionic-storage';
import { ChallengeActions, QuestionPopoverActions } from '../actions';
import * as fromRoot from '../reducers';
import * as fromStore from '../selectors';
import { ApiService } from '@app/services';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class ChallengeEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private loadingCtrl: LoadingController,
    private api: ApiService
  ) {}

  // .then(() => fetch().then((res) => res.blob()))

  requestMediaUpload = createEffect(() =>
    this.actions$.pipe(
      ofType(ChallengeActions.mediaCreated),
      withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectSession)))),
      mergeMap(([{ base64, filename }, session]) => {
        let indicator: HTMLIonLoadingElement;
        return from(
          this.loadingCtrl.create().then((loading) => {
            indicator = loading;
            return loading.present();
          })
        ).pipe(
          mergeMap(() =>
            forkJoin([
              this.api.create(`journeys/${session}/create_media_challenge_upload`, { filename }),
              fetch(base64).then((res) => res.blob()),
            ])
          ),
          mergeMap(([{ url }, file]) => this.api.upload(url, file)),
          map(() => ChallengeActions.uploadSuccess({ base64, filename })),
          catchError((error) =>
            of(ChallengeActions.uploadFailure('Fehler Hochladen der Datei', error))
          ),
          finalize(() => indicator.dismiss())
        );
      })
    )
  );

  restoreChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageSyncActions.HYDRATED),
      withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectChallengeData)))),
      filter(([_, data]) => data && data.challenge && !!data.challenge.timestamp),
      mergeMap(([_, { challenge, status }]) => {
        switch (challenge.criteria.type) {
          case 'attempts':
            return this.trackAttemptsCriteria(challenge.criteria, status).pipe(
              startWith(ChallengeActions.restored(challenge.id))
            );
          case 'timelimit':
            return this.trackTimelimitCriteria(challenge.criteria, challenge.id, status).pipe(
              startWith(ChallengeActions.restored(challenge.id))
            );
        }
      })
    )
  );

  startChallenge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionPopoverActions.startChallenge),
      withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectCurrentCriteria)))),
      switchMap(([{ id }, criteria]) => {
        switch (criteria.type) {
          case 'attempts':
            return this.trackAttemptsCriteria(criteria);
          case 'timelimit':
            return this.trackTimelimitCriteria(criteria, id);
        }
      })
    )
  );

  private trackAttemptsCriteria(criteria: AttemptsCriteria, oldStatus?: CriteriaStatus) {
    return this.actions$.pipe(
      ofType(QuestionPopoverActions.challengeAttempted),
      takeUntil(this.actions$.pipe(ofType(QuestionPopoverActions.endChallenge))),
      withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectCriteriaStatus)))),
      map(([{ id, successful }, status]) => {
        const tries = (status as AttemptsCriteriaStatus).tries + 1;
        return successful
          ? ChallengeActions.challengeSucceeded({
              id,
              message: criteria.attempts[tries - 1].successText,
              points: criteria.attempts[tries - 1].points,
            })
          : tries < criteria.attempts.length
          ? ChallengeActions.statusUpdated({
              message: criteria.attempts[tries - 1].failureText,
              status: {
                tries,
                display: {
                  label: 'Versuch',
                  value: `${tries} / ${criteria.attempts.length}`,
                },
              },
            })
          : ChallengeActions.challengeFailed({
              id,
              message: criteria.attempts[tries - 1].failureText,
            });
      }),
      startWith(
        ChallengeActions.statusUpdated({
          message: null,
          status: oldStatus
            ? oldStatus
            : {
                tries: 0,
                display: {
                  label: 'Versuch',
                  value: `0 / ${criteria.attempts.length}`,
                },
              },
        })
      )
    );
  }

  private trackTimelimitCriteria(
    criteria: TimelimitCriteria,
    challengeId: string,
    oldStatus?: CriteriaStatus
  ) {
    return merge(
      this.trackTimelimitAttempts(criteria),
      this.trackTimelimitTimeout(challengeId, criteria),
      this.trackTimelimitStatus(criteria)
    ).pipe(
      takeUntil(this.actions$.pipe(ofType(QuestionPopoverActions.endChallenge))),
      startWith(
        ChallengeActions.statusUpdated({
          message: null,
          status: oldStatus
            ? oldStatus
            : {
                startTime: Math.round(Date.now() / 1000),
                display: {
                  label: 'Restzeit',
                  value: formatDuration(criteria.timelimit),
                },
              },
        })
      )
    );
  }

  private trackTimelimitStatus(criteria: TimelimitCriteria) {
    return interval(1000).pipe(
      takeUntil(
        this.actions$.pipe(
          ofType(ChallengeActions.challengeSucceeded, ChallengeActions.challengeFailed)
        )
      ),
      withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectCriteriaStatus)))),
      map(([_, status]) =>
        ChallengeActions.statusUpdated({
          message: null,
          status: {
            ...status,
            display: {
              label: 'Restzeit',
              value: formatDuration(
                criteria.timelimit,
                Math.round(Date.now() / 1000) - (status as TimelimitCriteriaStatus).startTime
              ),
            },
          },
        })
      )
    );
  }

  private trackTimelimitTimeout(id: string, criteria: TimelimitCriteria) {
    return timer(criteria.timelimit * 1000).pipe(
      mapTo(ChallengeActions.challengeFailed({ id, message: criteria.failureText }))
    );
  }

  private trackTimelimitAttempts(criteria: TimelimitCriteria) {
    return this.actions$.pipe(
      ofType(QuestionPopoverActions.challengeAttempted),
      withLatestFrom(defer(() => this.store.pipe(select(fromStore.selectCriteriaStatus)))),
      map(([{ id, successful }, status]) =>
        successful
          ? ChallengeActions.challengeSucceeded({
              id,
              message: criteria.successText,
              points: criteria.points,
            })
          : ChallengeActions.statusUpdated({
              message: criteria.attemptText,
              status,
            })
      )
    );
  }
}
