import { createErrorAction, createTimestampedAction, props } from '@app/helpers';

import { Rank } from '@app/models';

enum Action {
  LOAD_FAILURE = '[Ranking Api] Load Failure',
  LOAD_SUCCESS = '[Ranking Api] Load Success',
  UPDATE_RANKING = '[Ranking Page] Update Ranking',
}

export const loadFailure = createErrorAction(Action.LOAD_FAILURE);
export const loadSuccess = createTimestampedAction(
  Action.LOAD_SUCCESS,
  props<{ ranking: Rank[] }>()
);
export const updateRanking = createTimestampedAction(Action.UPDATE_RANKING);
