import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Score } from '@app/models';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent implements OnInit {
  @Input() public score: Score;
  @Input() public icon = 'ellipsis-vertical';
  @Input() public backButton = false;
  @Output() public options = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}
