import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ViewerModalComponent } from 'ngx-ionic-image-viewer';

@Component({
  selector: 'app-preview-popover',
  templateUrl: './preview-popover.component.html',
  styleUrls: ['./preview-popover.component.scss'],
})
export class PreviewPopoverComponent implements OnInit {
  @Input() previewType = 'preview';
  @Input() content: any;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  close() {
    this.modalCtrl.getTop().then((modal) => modal.dismiss());
  }

  async openViewer(src: string) {
    const modal = await this.modalCtrl.create({
      component: ViewerModalComponent,
      componentProps: {
        src,
        scheme: 'dark',
      },
      cssClass: 'ion-img-viewer',
      keyboardClose: true,
      showBackdrop: true,
    });

    return await modal.present();
  }
}
