import { environment } from '@app/env';
import { GeoCoordinates, RouteListItem, RouteVersionInfo, Tile } from '@app/models';
import { Entry } from '@ionic-native/file/ngx';
import { LatLngBounds, Map, Point } from 'leaflet';
import { eqBy, has, keys, partition, prop, symmetricDifferenceWith, union, indexBy } from 'ramda';

const unionKeys = <T, V>(x: { [id: string]: T }, y: { [id: string]: V }) =>
  union(keys(x), keys(y)) as string[];
const indexById = indexBy(prop('id') as <T extends Record<'id', string>>(obj: T) => string);
const getX = (lng: number, z: number) => Math.floor(((lng + 180) / 360) * Math.pow(2, z));
const getY = (lat: number, z: number) => {
  let y = Math.floor(
    ((1 -
      Math.log(Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)) / Math.PI) /
      2) *
      Math.pow(2, z)
  );
  // Y calculation for TMS
  return environment.map.useTms ? Math.pow(2, z) - y - 1 : y;
};

export const getRouteUpdates = (
  localRoutes: RouteListItem[],
  remoteRoutes: RouteVersionInfo[]
): { remove: string[]; install: RouteVersionInfo[] } => {
  const localById = indexById(localRoutes);
  const remoteById = indexById(remoteRoutes);

  return unionKeys(localById, remoteById).reduce(
    ({ remove, install }, id) => ({
      install:
        !localById[id] || (remoteById[id] && localById[id].version < remoteById[id].version)
          ? [...install, remoteById[id]]
          : install,
      remove:
        !remoteById[id] || (localById[id] && localById[id].version < remoteById[id].version)
          ? [...remove, id]
          : remove,
    }),
    { remove: [], install: [] }
  );
};

export const getMapBounds = (
  routeBounds: LatLngBounds,
  mapWidth: number,
  mapHeight: number
): LatLngBounds => {
  const size = Math.max(mapWidth, mapHeight);
  const FixedMap: typeof Map = Map.extend({
    getSize: () => new Point(size, size),
  });

  const map: Map = new FixedMap(document.createElement('div'), {
    zoom: environment.map.minZoom,
    center: routeBounds.getCenter(),
  });

  return map.getBounds();
};

export const getRouteBounds = (geolocations: GeoCoordinates[]) =>
  geolocations.reduce(
    (latlngbounds, location) => latlngbounds.extend(location),
    new LatLngBounds([])
  );

export const getTilelist = (
  routeBounds: LatLngBounds,
  maxWidth: number,
  maxHeight: number
): Tile[] => {
  const xyzlist: {
    x: number;
    y: number;
    z: number;
  }[] = [];

  const bounds = getMapBounds(routeBounds, maxWidth, maxHeight);

  for (let z = environment.map.minZoom; z <= environment.map.maxZoom; z++) {
    const t1_x = getX(bounds.getWest(), z);
    const t1_y = getY(bounds.getNorth(), z);
    const t2_x = getX(bounds.getEast(), z);
    const t2_y = getY(bounds.getSouth(), z);
    // Now that we have the coordinates of the two opposing points (in the correct order!), we can iterate over the square.
    for (let x = t1_x; x <= t2_x; x++) {
      for (let y = t1_y; y <= t2_y; y++) {
        xyzlist.push({ x, y, z });
      }
    }
  }

  const domains = environment.map.subdomains || 'abc';

  return xyzlist.map(({ x, y, z }) => {
    const domain = domains[Math.floor(Math.random() * domains.length)];
    const tile: Tile = {
      domain,
      name: (environment.map.fileProvider || '')
        .replace('{z}', z.toString())
        .replace('{x}', x.toString())
        .replace('{y}', y.toString()),
      url: environment.map.provider
        .replace('{z}', z.toString())
        .replace('{x}', x.toString())
        .replace('{y}', y.toString())
        .replace('{s}', domain),
    };

    if (environment.map.fallback) {
      tile.fallback = environment.map.fallback
        .replace('{z}', z.toString())
        .replace('{x}', x.toString())
        .replace('{y}', y.toString())
        .replace('{s}', domain);
    }

    return tile;
  });
};

export const getDownloadAndDeleteTiles = (localTiles: Entry[], routesTiles: Tile[]) =>
  partition(
    has('url'),
    symmetricDifferenceWith<Entry | Tile>((a, b) => a.name === b.name, localTiles, routesTiles)
  ) as [Tile[], Entry[]];
