import { GeoCoordinates } from './location.model';

// type TriggerTypes = 'station' | 'time' | 'geoposition';

export enum TriggerTypes {
  STATION = 'station',
  TIME = 'time',
  GEOPOSITION = 'geoposition',
}

export enum TriggerStatus {
  PENDING = 'PENDING',
  TRIGGERED = 'TRIGGERED',
  DONE = 'DONE',
}

export interface TriggerType<Type extends TriggerTypes> {
  type: Type;
}

export interface StationTrigger extends TriggerType<TriggerTypes.STATION> {
  station: string;
}

export interface TimeTrigger extends TriggerType<TriggerTypes.TIME> {
  minutes: number;
}

export interface GeolocationTrigger extends TriggerType<TriggerTypes.GEOPOSITION> {
  geoposition: GeoCoordinates;
}

export type Trigger = StationTrigger | TimeTrigger | GeolocationTrigger;

export interface TriggerData {
  id: string;
  status: TriggerStatus;
}

export type StationTriggerEntity = StationTrigger & TriggerData;
export type TimeTriggerEntity = TimeTrigger & TriggerData;
export type GeolocationTriggerEntity = GeolocationTrigger & TriggerData;

export type TriggerEntity = StationTriggerEntity | TimeTriggerEntity | GeolocationTriggerEntity;
export type TriggerOfType<T extends TriggerTypes> = EntityOfType<TriggerEntity, T>;
