import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { RootState, fromStore, QuestionPopoverActions } from '@app/root-store';
import { ModalController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-question-popover',
  templateUrl: './question-popover.component.html',
  styleUrls: ['./question-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionPopoverComponent implements OnInit {
  @Input() id: string;

  public data$ = this.store.pipe(
    select(fromStore.selectChallengePage),
    tap((data) => {
      if (
        data.page &&
        data.page.type === 'page' &&
        !data.pagination.hasNext &&
        !data.challenge.timestamp
      ) {
        setTimeout(() => this.store.dispatch(QuestionPopoverActions.startChallenge(this.id)), 0);
      }
    })
  );

  constructor(private modalCtrl: ModalController, private store: Store<RootState>) {}

  ngOnInit() {}

  attempted(successful: boolean) {
    this.store.dispatch(QuestionPopoverActions.challengeAttempted({ id: this.id, successful }));
  }

  nextPage(hasNext: boolean) {
    if (hasNext) {
      this.store.dispatch(QuestionPopoverActions.nextPage());
    } else {
      this.modalCtrl.dismiss({ id: this.id }, 'end-challenge');
    }
  }

  prevPage() {
    this.store.dispatch(QuestionPopoverActions.prevPage());
  }
}
