import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '@app/env';
import { PreviewGuard } from './guards/preview.guard';

const routes: Routes = [
  {
    path: 'password',
    loadChildren: () =>
      import('./pages/password/password.module').then((m) => m.PasswordPageModule),
  },
  {
    path: 'manage',
    loadChildren: () => import('./pages/manage/manage.module').then((m) => m.ManagePageModule),
  },
  {
    path: 'journey',
    loadChildren: () => import('./pages/journey/journey.module').then((m) => m.JourneyPageModule),
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then((m) => m.ChatPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'routes',
    loadChildren: () => import('./pages/routes/routes.module').then((m) => m.RoutesPageModule),
  },
  {
    path: 'voucher-code-error',
    loadChildren: () =>
      import('./pages/voucher-code-error/voucher-code-error.module').then(
        (m) => m.VoucherCodeErrorPageModule
      ),
  },
  {
    path: 'finished/:customerId',
    loadChildren: () =>
      import('./pages/finished/finished.module').then((m) => m.FinishedPageModule),
  },
  {
    path: 'preview',
    loadChildren: () => import('./pages/preview/preview.module').then((m) => m.PreviewPageModule),
    canActivate: [PreviewGuard],
  },
  {
    path: '',
    redirectTo: environment.native
      ? 'password'
      : environment.feature.route
      ? 'manage'
      : 'finished/wiesbaden',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [PreviewGuard],
})
export class AppRoutingModule {}
