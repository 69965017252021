import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() pagination: { hasNext: boolean; hasPrev: boolean; prevText: string; nextText: string };
  @Output() nextPage = new EventEmitter<boolean>();
  @Output() prevPage = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}
