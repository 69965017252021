import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/env';
import { fromStore, RootState } from '@app/root-store';
import { select, Store } from '@ngrx/store';
import { mergeMap, take, tap } from 'rxjs/operators';
import { CustomerService } from '@app/services';

@Injectable()
export class DeviceInterceptor implements HttpInterceptor {
  constructor(private customer: CustomerService, private store: Store<RootState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (environment.native || !!environment.feature.route) {
      return this.customer.token$.pipe(
        take(1),
        mergeMap((token) =>
          next.handle(
            req.url.startsWith(environment.apiHost)
              ? req.clone({ setHeaders: { Device: token } })
              : req
          )
        )
      );
    } else {
      return this.store.pipe(
        select(fromStore.selectVoucherCode),
        take(1),
        mergeMap((code) =>
          next.handle(
            code === null || /.*\.json$/.test(req.url)
              ? req
              : req.clone({
                  setHeaders: { Authorization: 'VoucherCode ' + code },
                })
          )
        )
      );
    }
  }
}
