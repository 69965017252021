import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Component } from '@angular/core';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform, ModalController } from '@ionic/angular';
import { noop, Subject, timer } from 'rxjs';
import { buffer, exhaustMap, map, startWith, tap } from 'rxjs/operators';
import { ButtonPress } from '@app/models';
import { environment } from '@app/env';
import { log } from '@app/helpers';
import { Store } from '@ngrx/store';
import { JourneyPageActions, RootState } from '@app/root-store';

declare var KioskPlugin: any;
declare var window;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [AndroidFullScreen],
})
export class AppComponent {
  private keys$ = new Subject<ButtonPress>();

  constructor(
    private store: Store<RootState>,
    platform: Platform,
    statusBar: StatusBar,
    splashScreen: SplashScreen,
    screenOrientation: ScreenOrientation,
    androidFullscreen: AndroidFullScreen,
    private modalCtrl: ModalController
  ) {
    registerLocaleData(localeDe);

    platform.ready().then((readySource) => {
      if (readySource === 'cordova') {
        screenOrientation.lock(screenOrientation.ORIENTATIONS.LANDSCAPE);
        androidFullscreen.immersiveMode();
        statusBar.styleDefault();
        statusBar.hide();
        splashScreen.hide();
        platform.backButton.subscribeWithPriority(1000, noop);
        this.registerCodes();
      }
    });
  }

  registerCodes() {
    if (environment.codes) {
      document.addEventListener(
        'volumeupbutton',
        () => {
          log(ButtonPress.VOLUMEUP);
          this.keys$.next(ButtonPress.VOLUMEUP);
        },
        false
      );
      document.addEventListener(
        'volumedownbutton',
        () => {
          log(ButtonPress.VOLUMEDOWN);
          this.keys$.next(ButtonPress.VOLUMEDOWN);
        },
        false
      );

      const codemap: { [code: string]: string } = {};

      if (environment.codes.exitkiosk && environment.codes.exitkiosk.length > 0) {
        codemap[environment.codes.exitkiosk.join('')] = 'exitkiosk';
      }
      if (environment.codes.resetapp && environment.codes.resetapp.length > 0) {
        codemap[environment.codes.resetapp.join('')] = 'resetapp';
      }
      if (environment.codes.closemodal && environment.codes.closemodal.length > 0) {
        codemap[environment.codes.closemodal.join('')] = 'closemodal';
      }

      log(codemap);

      this.keys$
        .pipe(
          exhaustMap((key) =>
            this.keys$.pipe(
              startWith(key),
              buffer(timer(environment.codes.timelimit)),
              map((input) => (input as (keyof typeof ButtonPress)[]).join(''))
            )
          )
        )
        .subscribe((code) => {
          switch (codemap[code]) {
            case 'closemodal':
              const closeNext = () =>
                this.modalCtrl.getTop().then((modal) => {
                  if (modal) {
                    modal.dismiss().then(() => closeNext());
                  }
                });
              closeNext();
              break;
            case 'exitkiosk':
              KioskPlugin.exitKiosk();
              break;
            case 'resetapp':
              this.store.dispatch(JourneyPageActions.endJourney());
              break;
            default:
              log(code);
              break;
          }
        });
    }
  }
}
