import { Injectable } from '@angular/core';
import { environment } from '@app/env';
import {
  flattenObj,
  getDownloadAndDeleteTiles,
  getRouteBounds,
  getRouteUpdates,
  getTilelist,
  log,
  tapOnce,
} from '@app/helpers';
import {
  journeyOf,
  JourneyParams,
  JourneyResponse,
  Route,
  RouteListItem,
  RouteVersionInfo,
  Tile,
} from '@app/models';
import { Entry } from '@ionic-native/file/ngx';
import { LoadingController, Platform } from '@ionic/angular';
import * as Ajv from 'ajv';
import {
  assocPath,
  flatten,
  groupBy,
  mergeDeepRight,
  prop,
  sortBy,
  splitEvery,
  toPairs,
  uniqBy,
} from 'ramda';
import { concat, EMPTY, from, Observable, pipe } from 'rxjs';
import {
  concatAll,
  concatMap,
  finalize,
  ignoreElements,
  map,
  mergeMap,
  mergeMapTo,
  scan,
  shareReplay,
  tap,
  toArray,
  withLatestFrom,
} from 'rxjs/operators';
import { ApiService } from './api.service';
import { FileService } from './file.service';
import { catchError } from 'rxjs/operators';

const toPercentage = (max: number, value: number) => `${Math.ceil((value / max) * 100)}%`;
const mergeMapOver = <T, V>(fn: (x: T) => Observable<V>) =>
  pipe(concatAll<T>(), mergeMap(fn), toArray());

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  private validator$ = this.api.readAsset('./assets/schema/route.schema.json').pipe(
    map((schema) =>
      new Ajv({
        useDefaults: true,
        allErrors: true,
      }).compile(schema)
    ),
    shareReplay(1)
  );

  private indicator: HTMLIonLoadingElement;

  constructor(
    private loadingCtrl: LoadingController,
    private api: ApiService,
    private file: FileService,
    private platform: Platform
  ) {}

  loadRoute(id: string) {
    log('loadRoute', id);
    return this.file.loadJSON<Route>(`${environment.routesBase}/${id}`, 'route.json');
  }

  loadRoutesList() {
    return this.showLoadingStatus('Lade Routenliste').pipe(
      mergeMapTo(this.getRouteList()),
      finalize(() => this.hideLoadingStatus())
    );
  }

  startRoute(options: JourneyParams) {
    return this.showLoadingStatus('Starte Route').pipe(
      mergeMapTo(this.api.create<JourneyResponse>('journeys', options)),
      finalize(() => this.hideLoadingStatus())
    );
  }

  resetRoutes() {
    log('resetRoutes');
    return this.showLoadingStatus('Deinstalliere Routen').pipe(
      mergeMapTo(this.file.rmDir(environment.routesBase)),
      finalize(() => this.hideLoadingStatus())
    );
  }

  resetTiles() {
    log('resetTiles');
    return this.showLoadingStatus('Deinstalliere Karten').pipe(
      mergeMapTo(this.file.rmDir('tiles')),
      finalize(() => this.hideLoadingStatus())
    );
  }

  downloadTiles() {
    log('downloadTiles');
    return !environment.feature.offline
      ? EMPTY
      : this.showLoadingStatus('Aktualisiere Karten').pipe(
          mergeMapTo(this.getRoutesTileList()),
          withLatestFrom(this.file.ls('tiles')),
          map(([neededTiles, localTiles]) => getDownloadAndDeleteTiles(localTiles, neededTiles)),
          mergeMap(([installTiles, removeTiles]) =>
            concat(this.removeTiles(removeTiles), this.installTiles(installTiles))
          ),
          finalize(() => this.hideLoadingStatus())
        );
  }

  loadRouteData(id: string) {
    return this.loadRoute(id).pipe(
      withLatestFrom(this.validator$),
      map(([route, validator]) => {
        if (!validator(route)) {
          const errors: [string, string][] = toPairs(
            flattenObj(
              validator.errors
                .filter((err) => ['anyOf', 'enum'].indexOf(err.keyword) === -1)
                .map((err) => ({
                  path: err.dataPath
                    .replace(/(\[\d\])/g, '.$1')
                    .split('.')
                    .slice(1),
                  err,
                }))
                .sort((a, b) => a.path.length - b.path.length)
                .reduce(
                  (acc, val) => mergeDeepRight(acc, assocPath(val.path, val.err.message, {})),
                  {}
                )
            )
          );

          if (errors.length) {
            const errorMessages = errors
              .reduce(
                (acc, [path, message]) => [
                  ...acc,
                  `${path.replace(/\.\[/g, '[')}:`,
                  `- ${message}\n`,
                ],
                ['Validierungsfehler:\n']
              )
              .join('\n');

            if (!!environment.feature.preview) {
              alert(errorMessages);
            } else {
              throw new Error(errorMessages);
            }
          }
        }

        return journeyOf(
          this.file.toFileUrl(this.file.getRoot(`${environment.routesBase}/${id}`)),
          route
        );
      })
    );
  }

  updateRoutesList(localRoutes: RouteListItem[]) {
    return this.showLoadingStatus('Aktualisiere Routenliste').pipe(
      mergeMapTo(this.api.read<RouteVersionInfo[]>('routes')),
      map((remoteRoutes) => getRouteUpdates(localRoutes, remoteRoutes)),
      mergeMap(({ remove, install }) =>
        concat(this.removeRoutes(remove), this.installRoutes(install), this.getRouteList())
      ),
      finalize(() => this.hideLoadingStatus())
    );
  }

  private getRouteList() {
    log('getRouteList');
    return this.file.ls(environment.routesBase).pipe(
      mergeMapOver((entry) =>
        this.loadRoute(entry.name).pipe(
          map(
            ({ id, version, info: { title, description, categories } }): RouteListItem => ({
              id,
              version,
              title,
              description,
              categories,
            })
          )
        )
      ),
      map(sortBy(prop('title')))
    );
  }

  private getRoutesTileList() {
    log('getRoutesTileList');
    return this.file.ls(environment.routesBase).pipe(
      mergeMapOver((entry) =>
        this.loadRoute(entry.name).pipe(
          map(({ stations }) => (stations || []).map(({ geoposition }) => geoposition)),
          map((locations) => getRouteBounds(locations)),
          map((bounds) => getTilelist(bounds, this.platform.width(), this.platform.height()))
        )
      ),
      map((tiles) => uniqBy(prop('name'), flatten(tiles)))
    );
  }

  private removeRoutes(routes: string[]) {
    return from(routes).pipe(
      tapOnce(() => {
        log('removeRoutes', routes);
        this.updateLoadingStatus('Entferne alte Routen 0%');
      }),
      concatMap((route) => this.file.rmDir(`${environment.routesBase}/${route}`)),
      scan((acc) => acc + 1, 0),
      tap((done) =>
        this.updateLoadingStatus(`Entferne alte Routen ${toPercentage(routes.length, done)}`)
      ),
      ignoreElements()
    );
  }

  private installRoutes(routes: RouteVersionInfo[]) {
    return from(routes).pipe(
      tapOnce(() => {
        log('installRoutes', routes);
        this.updateLoadingStatus('Installiere Routen 0%');
      }),
      concatMap((route) => this.file.install(route.file, `${environment.routesBase}/${route.id}`)),
      scan((acc) => acc + 1, 0),
      tap((done) =>
        this.updateLoadingStatus(`Installiere Routen ${toPercentage(routes.length, done)}`)
      ),
      ignoreElements()
    );
  }

  private removeTiles(tiles: Entry[]) {
    return from(splitEvery(5, tiles)).pipe(
      tapOnce(() => {
        log('removeTiles', tiles.length);
        this.updateLoadingStatus('Entferne alte Karten 0%');
      }),
      mergeMap((groupTiles) =>
        from(groupTiles).pipe(concatMap((tile) => this.file.rmFile(`tiles/${tile.name}`)))
      ),
      scan((acc) => acc + 1, 0),
      tap((done) =>
        this.updateLoadingStatus(`Entferne alte Karten ${toPercentage(tiles.length, done)}`)
      ),
      ignoreElements()
    );
  }

  private installTiles(tiles: Tile[]) {
    return from(Object.values(groupBy(prop('domain'), tiles))).pipe(
      tapOnce(() => {
        log(`installTiles`, tiles.length);
        this.updateLoadingStatus('Installiere Karten 0%');
      }),
      mergeMap((domainTiles) =>
        from(domainTiles).pipe(
          concatMap((tile) =>
            this.file.download(tile.url, `tiles/${tile.name}`).pipe(
              catchError((e) => {
                if (tile.fallback) {
                  return this.file.download(tile.fallback, `tiles/${tile.name}`);
                }
                throw e;
              })
            )
          )
        )
      ),
      scan((acc) => acc + 1, 0),
      tap((done) =>
        this.updateLoadingStatus(`Installiere Karten ${toPercentage(tiles.length, done)}`)
      ),
      ignoreElements()
    );
  }

  private showLoadingStatus(message: string) {
    return from(
      this.loadingCtrl.create({ message }).then((loading) => {
        this.hideLoadingStatus();
        this.indicator = loading;
        return loading.present();
      })
    );
  }
  private hideLoadingStatus() {
    if (!!this.indicator) {
      this.indicator.dismiss();
      this.indicator = null;
    }
  }
  private updateLoadingStatus(message: string) {
    if (!!this.indicator && message !== this.indicator.message) {
      this.indicator.message = message;
    }
  }
}
