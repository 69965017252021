import { createTimestampedAction, props, createErrorAction, createIdAction } from '@app/helpers';
import { CriteriaStatus } from '@app/models';

enum Action {
  RESTORED = '[Challenge] Restored',
  STATUS_UPDATED = '[Challenge] Status Updated',
  CHALLENGE_FAILED = '[Challenge] Challenge Failed',
  CHALLENGE_SUCCEEDED = '[Challenge] Challenge Succeeded',
  MEDIA_CREATED = '[Challenge] Media Created',
  MEDIA_ERROR = '[Challenge] Media Error',
  UPLOAD_REQUESTED = '[Challenge] Media Upload Requested',
  UPLOAD_SUCCESS = '[Challenge] Media Upload Success',
  UPLOAD_FAILURE = '[Challenge] Media Upload Failure',
}

export const restored = createIdAction(Action.RESTORED);

export const statusUpdated = createTimestampedAction(
  Action.STATUS_UPDATED,
  props<{ status: CriteriaStatus; message: string }>()
);
export const challengeSucceeded = createTimestampedAction(
  Action.CHALLENGE_SUCCEEDED,
  props<{ id: string; points: number; message: string }>()
);
export const challengeFailed = createTimestampedAction(
  Action.CHALLENGE_FAILED,
  props<{ id: string; message: string }>()
);
export const mediaCreated = createTimestampedAction(
  Action.MEDIA_CREATED,
  props<{ base64: string; filename: string }>()
);
export const mediaError = createErrorAction(Action.MEDIA_ERROR);

export const uploadRequested = createTimestampedAction(Action.UPLOAD_REQUESTED);
export const uploadSuccess = createTimestampedAction(
  Action.UPLOAD_SUCCESS,
  props<{ base64: string; filename: string }>()
);
export const uploadFailure = createErrorAction(Action.UPLOAD_FAILURE);
